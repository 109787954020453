import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Compressor from "compressorjs";
import { toast } from "react-toastify";
import moment from "moment";
import useAxiosClient from "../../hooks/useAxiosClient";
import {
  TCrop,
  TDestinationPartner,
  TTradeCompleteDetai,
  TTradeDocument,
} from "../../types";
import { TRootState } from "../../store";
import {
  setSelectedDate,
  updateCropData,
  updatePartyData,
  updateTradeId,
} from "../../reducers/tradeSlice";
import useOrderView from "./useOrderView";
const useEditTrade = ({
  traderId,
  tradeId,
  mode,
  closeModal,
}: {
  traderId: number;
  tradeId: number;
  mode: string;
  closeModal?: any;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handlePostCall, handlePutCall, handleGetCall } = useAxiosClient();
  const { getTradeDetail } = useOrderView({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
  });
  const {
    tradeDetails,
    tradeEditStatus,
    selectedDate,
    selectedCrop,
    selectedTrader,
    partyData,
    deletedCrop,
    selectedSrcParty,
    adminId,
    role,
    tententType,
  } = useSelector((state: TRootState) => ({
    tradeDetails: state.trades.tradeDetails,
    tradeEditStatus: state.trades.tradeEditStatus,
    selectedDate: state.trades.selectedDate,
    selectedCrop: state.trades.selectedCrop,
    selectedTrader: state.trades.selectedParty,
    partyData: state.trades.partyData,
    deletedCrop: state.trades.deletedCrop,
    selectedSrcParty: state.trades.selectedSrcParty,
    adminId: state.auth.adminId,
    role: state.auth.userInfo?.roleType,
    tententType: state.auth.userInfo?.type,
  }));
  const [tradeType, setTradeType] = useState("DEST");
  const [tradeDetail, setTradeDetail] = useState<TTradeCompleteDetai | null>(
    null
  );
  const [traderData, setTraderData] = useState<TDestinationPartner[]>([]);
  const [freightAmt, setFreightAmt] = useState(
    mode === "new" ? 0 : tradeDetail?.freightAmt
  );
  const [invoiceAmt, setInvoiceAmt] = useState(
    mode === "new" ? 0 : tradeDetail?.invAmt
  );
  const [freightAdv, setAdvanceAmt] = useState(
    mode === "new" ? 0 : tradeDetail?.freightAdv
  );
  const [tradecomments, setComments] = useState(
    mode === "new" ? "" : tradeDetail?.tradecomments
  );
  const [tradeTypeMode, setTradeTypeMode] = useState(
    mode === "new" ? "" : tradeDetail?.tradeMode!
  );
  const [srcVehNum, setTradeTruckNumber] = useState(
    mode === "new" ? "" : tradeDetail?.srcVehNum
  );
  const [commodityCost, setCommodityCost] = useState(
    mode === "new" ? 0 : tradeDetail?.commodityCost
  );
  const [removeImageStatus, setRemoveImageStatus] = useState(false);
  const onChangeTradeMode = (val: any) => {
    setTradeTypeMode(val);
  };
  const [calenderOpen, setCalenderOpen] = useState(false);
  /** Function to handle date selection */
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    dispatch(setSelectedDate(_date));
    setCalenderOpen(false);
  };
  const [selectedPartner, setSeletedPartner] =
    useState<TDestinationPartner | null>(null);
  const [selectedSrc, setSeletedSrc] = useState<TDestinationPartner | null>(
    null
  );
  const [companyData, setComanyData] = useState<{
    srcRmName: string | "";
    srcRmMobile: string | "";
    destRmMobile: string | "";
    destRmName: string | "";
    company: string | "";
  }>({
    srcRmName: tradeDetails?.srcRmName || "",
    srcRmMobile: tradeDetails?.srcRmMobile || "",
    destRmMobile: tradeDetails?.destRmMobile || "",
    destRmName: tradeDetails?.destRmName || "",
    company: tradeDetails?.company || "",
  });
  useEffect(() => {
    if (selectedCrop == null) {
      let obj = {
        qtyUnit: "BAGS",
        weightUnit: "RATE_PER_KG",
        qty: 0,
        weight: 0,
        freightAmt: 0,
        status: 1,
        id: 0,
        cropName: "",
        cropId: 0,
        baseName: "",
        imageUrl: "",
        type: "",
        units: "",
        varietyName: "",
        rateType: "",
        tradeId: 0,
        addWastage: 0,
        destWeight: 0,
        destRate: 0,
        rate: 0,
        unitType: "KGS",
      };
      dispatch(updateCropData({ selectedCrop: obj }));
    }
  }, []);
  useEffect(() => {
    var weight = selectedCrop?.weight;
    if (
      selectedCrop?.weightUnit == "RATE_PER_KG" &&
      selectedCrop != null &&
      selectedCrop?.weight &&
      tententType.toUpperCase() == "BAYER"
    ) {
      if (selectedCrop?.unitType == "QUINTAL") {
        weight = selectedCrop?.weight * 100;
      } else if (selectedCrop?.unitType == "MT/TON") {
        weight = selectedCrop?.weight * 1000;
      } else {
        weight = selectedCrop?.weight;
      }
    }
    if (
      selectedCrop != null &&
      selectedCrop?.weight &&
      selectedCrop?.weightUnit == "RATE_PER_KG"
    ) {
      setCommodityCost(
        parseFloat((Number(selectedCrop?.rate) * Number(weight)).toFixed(2))
      );
    } else if (selectedCrop?.weightUnit == "RATE_PER_UNIT") {
      setCommodityCost(
        parseFloat(
          (Number(selectedCrop?.rate) * Number(selectedCrop?.qty)).toFixed(2)
        )
      );
    }
  }, [
    selectedCrop?.weightUnit,
    selectedCrop?.weight,
    selectedCrop?.qty,
    selectedCrop?.unitType,
  ]);
  useEffect(() => {
    setInvoiceAmt(Number(commodityCost) + Number(freightAmt));
  }, [commodityCost, freightAmt]);
  useEffect(() => {
    getPartyData(tradeType);
  }, [tradeType]);
  const getPartyData = (tradeType: string) => {
    handleGetCall<TDestinationPartner[]>(
      `/admin/trade-platform/parties/adminId/${adminId}/type/${tradeType}`
    ).then(({ data }) => {
      setTraderData(data);
      dispatch(updatePartyData({ partyData: data }));
    });
  };
  useEffect(() => {
    if (tradeDetail != null) {
      setTradeDetail({
        ...tradeDetail,
        company: companyData.company,
        srcRmMobile: companyData.srcRmMobile,
        srcRmName: companyData.srcRmName,
        destRmMobile: companyData.destRmMobile,
        destRmName: companyData.destRmName,
        freightAdv: freightAdv || 0,
        freightAmt: freightAmt || 0,
        tradecomments: tradecomments || "",
        invAmt: invoiceAmt || 0,
        tradeMode: tradeTypeMode || "",
        commodityCost: commodityCost || 0,
        srcVehNum: srcVehNum || "",
      });
    }
  }, [
    companyData.company,
    companyData.srcRmMobile,
    companyData.srcRmName,
    companyData?.destRmMobile,
    companyData?.destRmName,
  ]);
  const [searchText, setSearchText] = useState("");
  const handelSearch = (e: any) => {
    let searchtext = e.target.value;
    setSearchText(searchtext);
    if (searchText) {
      let searchTextLower = searchtext.toLowerCase();
      let filteredBuyers = partyData.filter((partner) => {
        if (
          (partner.tradeName &&
            partner.tradeName.toLowerCase().includes(searchTextLower)) ||
          (partner.addrLine &&
            partner.addrLine.toLowerCase().includes(searchText)) ||
          (partner.traderId &&
            partner.traderId.toString().includes(searchTextLower))
        ) {
          return true;
        } else {
          return false;
        }
      });
      setTraderData(filteredBuyers);
    } else {
      setTraderData(partyData);
    }
  };
  // clear serch
  const clearSearch = (type: string) => {
    if (type == "DEST") {
      setSearchText("");
      setTraderData(partyData);
      setSeletedPartner(null);
    } else {
      setSearchText("");
      setTraderData(partyData);
      setSeletedSrc(null);
    }
  };
  const [tapalInvoice, setTapalInvoice] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [receipts, setReceipts] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [otherDocuments, setOtherDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [tapalDelInvoice, setTapalDelInvoice] = useState<
    Array<TTradeDocument | null>
  >([]);
  const onUploadImage = async (
    type: "tapal" | "receipt" | "other",
    file: any
  ) => {
    let fileType = "";
    if (file.type === "application/pdf") {
      fileType = "pdf";
    } else if (file.type.includes("image/")) {
      fileType = "image";
    }
    let formdata = new FormData();
    formdata.append("traderId", traderId.toString());
    if (fileType === "pdf") {
      formdata.append("file", file, file.name.replace(" ", "-"));
      let imageUploadResponse = await handlePostCall<string[]>({
        URL: "/mandi/files/trade-portal/imgs/upload",
        apiParams: formdata,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      });
      let url = imageUploadResponse.data[0];
      if (type === "tapal") {
        setTapalInvoice([{ docType: "pdf", url: url }, ...tapalInvoice]);
      } else if (type === "receipt") {
        setReceipts([{ docType: "pdf", url: url }, ...receipts]);
      } else if (type === "other") {
        setOtherDocuments([{ docType: "pdf", url: url }, ...otherDocuments]);
      }
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          formdata.append(
            "file",
            compressedResult,
            file.name.replace(" ", "-")
          );
          let imageUploadResponse = await handlePostCall<string[]>({
            URL: "/mandi/files/trade-portal/imgs/upload",
            apiParams: formdata,
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          });
          let url = imageUploadResponse.data[0];
          if (type === "tapal") {
            setTapalInvoice([{ docType: "image", url: url }, ...tapalInvoice]);
          } else if (type === "receipt") {
            setReceipts([{ docType: "image", url: url }, ...receipts]);
          } else if (type === "other") {
            setOtherDocuments([
              { docType: "image", url: url },
              ...otherDocuments,
            ]);
          }
        },
      });
    }
  };
  const onRemoveImage = (
    type: "tapal" | "receipt" | "other",
    index: number,
    isExist: boolean = false
  ) => {
    if (type === "tapal") {
      if (isExist === true && tradeDetail !== null) {
        let invoices = [...tradeDetail.invoices];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setTradeDetail({ ...tradeDetail, invoices: invoices });
        setTapalDelInvoice(invoices);
        setRemoveImageStatus(true);
      } else {
        let newtapalInvoice = [...tapalInvoice];
        newtapalInvoice.splice(index, 1);
        setTapalInvoice(newtapalInvoice);
      }
    } else if (type === "receipt") {
      if (isExist === true && tradeDetail !== null) {
        let invoices = [...tradeDetail.receipts];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setTradeDetail({ ...tradeDetail, receipts: invoices });
        setRemoveImageStatus(true);
      } else {
        let newtapalInvoice = [...receipts];
        newtapalInvoice.splice(index, 1);
        setReceipts(newtapalInvoice);
      }
    } else if (type === "other") {
      if (isExist === true && tradeDetail !== null) {
        let invoices = [...tradeDetail.otherDocumets];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setTradeDetail({ ...tradeDetail, otherDocumets: invoices });
        setRemoveImageStatus(true);
      } else {
        let newtapalInvoice = [...otherDocuments];
        newtapalInvoice.splice(index, 1);
        setOtherDocuments(newtapalInvoice);
      }
    }
  };
  const onCreateTrade = async () => {
    if (selectedPartner == null) {
      toast.error("Please select Destination Trader");
      return;
    }
    if (selectedSrc == null) {
      toast.error("Please select Source Trader");
      return;
    }
    if (tradeTypeMode == "") {
      toast.error("Please select Trade mode");
      return;
    }
    if (selectedCrop != null && selectedCrop?.cropName == "") {
      toast.error("Please add Crop");
      return;
    }
    if (selectedCrop != null && selectedCrop?.qty == 0) {
      toast.error("Please enter Quantity");
      return;
    }
    if (selectedCrop != null && selectedCrop?.weight == 0) {
      toast.error("Please enter Weight");
      return;
    }
    if (freightAmt === "") {
      toast.error("Please enter Freight Amount");
      return;
    }
    if (freightAmt! > 0) {
      if (Number(freightAdv) > Number(freightAmt)) {
        toast.error("Freight Advance should be less than the Freight Amount");
        return;
      }
    }
    if (
      (commodityCost == "" || commodityCost == 0) &&
      tradeTypeMode == "ORDER"
    ) {
      toast.error("Please enter Commodity Cost ");
      return;
    }
    if ((invoiceAmt == "" || invoiceAmt == 0) && tradeTypeMode == "ORDER") {
      toast.error("Please enter Invoice Amount");
      return;
    }
    if (
      selectedCrop != null &&
      selectedCrop?.rate == 0 &&
      tradeTypeMode == "ORDER"
    ) {
      toast.error("Please enter Rate");
      return;
    }
    if (tapalInvoice.length === 0 && mode === "new") {
      toast.error("Please upload atleast one image for Tapal/Invoice");
      return;
    }
    // console.log(srcVehNum, "tradeTruckNumber");
    // if (srcVehNum == "" || srcVehNum == undefined) {
    //   toast.error("Please enter Truck number");
    //   return;
    // }
    if (tapalDelInvoice.length != 0) {
      if (
        tradeDetails?.invoices.length != 0 &&
        mode === "edit" &&
        tapalInvoice.length == 0
      ) {
        const deleted = tapalDelInvoice.every((item) => {
          return item?.status === 0;
        });
        if (deleted) {
          toast.error("Please upload atleast one image for Tapal/Invoice");
          return;
        }
      }
    }
    const toastid = toast.loading(
      mode === "new"
        ? "Creating Trade. Please wait..."
        : "Updateing Trade. Plase wait...",
      {
        type: "info",
      }
    );
    let destPartyId = selectedTrader?.traderId;

    let sourcePartyId = selectedSrc?.traderId;
    let srcNameText = mode === "edit" ? selectedSrc?.name : "";
    let formdata = new FormData();
    formdata.append("traderId", sourcePartyId!.toString());
    let images = tapalInvoice.concat(receipts, otherDocuments);
    for (let img of images) {
      //formdata.append("file", img, img.name.replace(" ", "-"));
    }
    try {
      let imgs: Array<{
        id: number;
        imgFrom: string;
        reqId: number;
        status: number;
        // tradeId: number;
        type: "tapal" | "receipt" | "other";
        url: string;
        addedBy: number;
        typeId: number;
      }> = [];
      let crops: Array<TCrop> = [];
      if (selectedCrop != null) {
        crops.push(selectedCrop);
        // crops.push({
        //   id: selectedCrop?.id,
        //   qtyUnit: selectedCrop.qtyUnit,
        //   cropId: selectedCrop.cropId,
        //   status: mode === "new" ? 1 : 2,
        //   qty: selectedCrop.qty || 0,
        //   tradeId: selectedCrop.tradeId || 0,
        //   weight: selectedCrop.weight || 0,
        //   weightUnit: selectedCrop.weightUnit,
        //   cropName: selectedCrop.cropName,
        //   imgUrl: selectedCrop.imageUrl,
        // });
      }
      if (mode === "edit") {
        if (tradeDetail?.invoices) {
          for (let image of tradeDetail.invoices) {
            if (image.status == 0) {
              imgs.push({
                ...image,
                // tradeId: tradeDetail.tradeId,
                addedBy: traderId,
                typeId: image.reqId,
              });
            }
          }
        }
        if (tradeDetail?.receipts) {
          for (let image of tradeDetail.receipts) {
            if (image.status == 0) {
              imgs.push({
                ...image,
                // tradeId: tradeDetail.tradeId,
                addedBy: traderId,
                typeId: image.reqId,
              });
            }
          }
        }
        if (tradeDetail?.otherDocumets) {
          for (let image of tradeDetail.otherDocumets) {
            if (image.status == 0) {
              imgs.push({
                ...image,
                // tradeId: tradeDetail.tradeId,
                addedBy: traderId,
                typeId: image.reqId,
              });
            }
          }
        }
        if (deletedCrop != null) {
          let obj = { ...deletedCrop };
          obj.status = 0;
          crops.push(obj);
        }
      }
      let currentTime = moment().format("HH:mm:ss");
      let time = selectedDate + " " + currentTime;
      if (images.length > 0) {
        if (tapalInvoice.length > 0) {
          for (let file of tapalInvoice) {
            imgs.push({
              id: 0,
              imgFrom: "SOURCE_TRADER",
              reqId: 0,
              status: 1,
              // tradeId: 0,
              type: "tapal",
              url: file.url,
              addedBy: traderId,
              typeId: 0,
            });
          }
        }
        if (receipts.length > 0) {
          for (let file of receipts) {
            imgs.push({
              id: 0,
              imgFrom: "SOURCE_TRADER",
              reqId: 0,
              status: 1,
              // tradeId: 0,
              type: "receipt",
              url: file.url,
              addedBy: traderId,
              typeId: 0,
            });
          }
        }
        if (otherDocuments.length > 0) {
          for (let file of otherDocuments) {
            imgs.push({
              id: 0,
              imgFrom: "SOURCE_TRADER",
              reqId: 0,
              status: 1,
              // tradeId: 0,
              type: "other",
              url: file.url,
              addedBy: traderId,
              typeId: 0,
            });
          }
        }
      }
      let postBody = {
        adminId: adminId,
        creation: {
          destPartyId: destPartyId,
          images: imgs,
          srcPartyId: sourcePartyId,
          srcName: srcNameText,
          date: selectedDate,
          timeStamp:
            mode === "edit"
              ? moment(tradeDetail?.timeStamp).format(
                  "YYYY-MM-DDTHH:mm:ss.SSSz"
                )
              : moment().format("YYYY-MM-DDTHH:mm:ss.SSSz"),
          tradeId: mode === "edit" ? tradeDetails?.tradeId : 0,
          destTradeStatus:
            mode === "edit" ? tradeDetails?.tradeStatus : "PENDING",
          // traderId: sourcePartyId,
          freightAmt: freightAmt,
          lineItems: crops,
          invAmt: invoiceAmt,
          tradeMode: tradeTypeMode,
          freightAdv: freightAdv,
          comments: tradecomments || "UPDATED",
          company: tradeDetail?.company,
          destRmMobile: tradeDetail?.destRmMobile,
          destRmName: tradeDetail?.destRmName,
          srcRmMobile: tradeDetail?.srcRmMobile,
          srcRmName: tradeDetail?.srcRmName,
          srcVehNum: srcVehNum,
          cmdityCost: tradeTypeMode == "ORDER" ? commodityCost : "",
        },
        tradeId: tradeDetails?.tradeId,
        tradeMode: tradeDetails?.tradeMode,
      };
      try {
        await handlePutCall<any>({
          URL: "admin/trade-platform/trades/metadata",
          apiParams: postBody,
        })
          // handlePostCall({
          //   URL: "/mandi/trade-portal/trades",
          //   apiParams: {
          //     destPartyId: destPartyId,
          //     images: imgs,
          //     srcPartyId: sourcePartyId,
          //     srcName: srcNameText,
          //     date: selectedDate,
          //     timeStamp:
          //       mode === "edit"
          //         ? moment(tradeDetail?.timeStamp).format(
          //             "YYYY-MM-DDTHH:mm:ss.SSSz"
          //           )
          //         : moment().format("YYYY-MM-DDTHH:mm:ss.SSSz"),
          //     tradeId: mode === "edit" ? tradeDetails?.tradeId : 0,
          //     tradeStatus:
          //       mode === "edit" ? tradeDetails?.tradeStatus : "PENDING",
          //     traderId: sourcePartyId,
          //     freightAmt: freightAmt,
          //     lineItems: crops,
          //     invAmt: invoiceAmt,
          //     tradeMode: tradeTypeMode,
          //     freightAdv: freightAdv,
          //     comments: tradecomments,
          //   },
          // })
          .then(({ data, status }) => {
            toast.update(toastid, {
              type: "success",
              render: status.description,
              isLoading: false,
              autoClose: 3000,
            });
            // navigate(`/order-view/${data}`);
            navigate(
              `/trade-detail/${tradeDetail?.srcPartyId}/${tradeDetail?.tradeId}?tab=OPEN`
            );
            getTradeDetail();
            closeModal(false);
            localStorage.setItem("activeTab", "tradeDetails");
            // let id = data != 0 ? (data as number) : 0;
            // dispatch(updateTradeId({ tradeId: id.toString() }));
          })
          .catch((err: any) => {
            toast.update(toastid, {
              type: "error",
              render: err.response.data.status.message,
              isLoading: false,
              autoClose: 3000,
            });
          });
      } catch (ex) {
        toast.update(toastid, {
          type: "error",
          render: "Create trade failed please try again",
          isLoading: false,
          autoClose: 2000,
        });
      }
    } catch (ex) {
      toast.update(toastid, {
        type: "error",
        render: "File upload failed please try again",
        isLoading: false,
        autoClose: 2000,
      });
    }
  };
  useEffect(() => {
    if (mode === "edit" && tradeDetails === null) {
      getTradeDetail();
    }
  }, []);
  useEffect(() => {
    if (tradeDetails !== null) {
      setTradeDetail(tradeDetails);
      setTapalDelInvoice(tradeDetails?.invoices);
      // setFreightAmt(tradeDetails?.freightAmt);
    }
  }, [tradeDetails]);
  return {
    setSeletedPartner,
    setSeletedSrc,
    tapalInvoice,
    receipts,
    otherDocuments,
    onUploadImage,
    onRemoveImage,
    onCreateTrade,
    tradeEditStatus,
    tradeDetail,
    handleDateSelection,
    calenderOpen,
    setCalenderOpen,
    selectedDate,
    selectedCrop,
    selectedTrader,
    partyData,
    handelSearch,
    clearSearch,
    searchText,
    traderData,
    selectedPartner,
    selectedSrc,
    setTraderData,
    setFreightAmt,
    freightAmt,
    tradeTypeMode,
    onChangeTradeMode,
    setInvoiceAmt,
    invoiceAmt,
    setTradeTypeMode,
    setAdvanceAmt,
    setComments,
    tradecomments,
    freightAdv,
    selectedSrcParty,
    setComanyData,
    companyData,
    setTradeType,
    tradeType,
    setTradeTruckNumber,
    srcVehNum,
    setCommodityCost,
    commodityCost,
    removeImageStatus,
    setTapalInvoice,
    setReceipts,
    setOtherDocuments,
    role,
    tententType,
  };
};
export default useEditTrade;

import React, { useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Input, Slider, Button, TabsList } from "../../components/ui";
import Trades from "../../components/home/trades";
import Supply from "../supply";
import Demand from "../demand";
import { useDispatch, useSelector } from "react-redux";
import { updateActiveTradeTab } from "../../reducers/tradeSlice";
import { TRootState } from "../../store";
const Home = () => {
  const dispatch = useDispatch();
  // const activeTradeTab = useSelector(
  //   (state: TRootState) => state.trades.activeTradeTab
  // );
  const { activeTradeTab, loginType } = useSelector((state: TRootState) => ({
    activeTradeTab: state.trades.activeTradeTab,
    loginType: state.auth.userInfo?.type,
  }));
  const [step, setStep] = useState(
    activeTradeTab != ""
      ? activeTradeTab
      : loginType == "BAYER"
      ? "SUPPLY"
      : "OPEN"
  );
  const [searchText, setSearchText] = useState("");
  const [onTabOpen, setOnTabOpen] = useState(false);
  console.log(step, activeTradeTab);
  return (
    <div className="container mx-auto py-4">
      <div className="flex border-b">
        {loginType == "BAYER" ? (
          <TabsList
            data={[
              { label: "Supply", value: "SUPPLY" },
              { label: "Demand", value: "DEMAND" },
              { label: "Open trades", value: "OPEN" },
              { label: "Closed trades", value: "CLOSED" },
            ]}
            activeTab={step}
            onChange={(tab, i) => {
              setStep(tab.value);
              dispatch(updateActiveTradeTab(tab.value));
              // setStep(tab.value === "OPEN" ? "OPEN" : "CLOSED");
              // setOnTabOpen(tab.value === "OPEN" ? true : false);
            }}
          />
        ) : (
          <TabsList
            data={[
              { label: "Open trades", value: "OPEN" },
              { label: "Closed trades", value: "CLOSED" },
            ]}
            activeTab={step}
            onChange={(tab, i) => {
              setStep(tab.value);
              dispatch(updateActiveTradeTab(tab.value));
              // setStep(tab.value === "OPEN" ? "OPEN" : "CLOSED");
              // setOnTabOpen(tab.value === "OPEN" ? true : false);
            }}
          />
        )}

        <div className="flex gap-4 ml-auto relative">
          <FaSearch
            className="absolute color-primary"
            size={15}
            style={{ top: "14px", left: "6px" }}
          />
          <Input
            type="text"
            palceholder="Search"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value.toLowerCase());
            }}
            inputStyle={{ textIndent: "22px", padding: "8px 5px" }}
          />
          {/* <Button>Record Payment</Button> */}
        </div>
      </div>
      <div>
        <div className="h-full pt-4">
          {step == "SUPPLY" && <Supply searchText={searchText} />}
          {step == "DEMAND" && <Demand searchText={searchText} />}
          {(step == "OPEN" || step == "CLOSED") && (
            <Trades status={step} searchText={searchText} displayMode="table" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;

import { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import PartnerInfo from "../../components/partner-info";
import SelectCrop from "../../components/select-crop";
import { Button, FileUploader, Input, RadioButton } from "../../components/ui";
import SelectDate from "../../components/ui/select-date";
import "./style.module.scss";
import useProposalEdit from "../../hooks/useProposalEdit";
import Select2, { OnoSelect } from "../../components/ui/select2";
import moment from "moment";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
const calculate = ({
  qty,
  rate,
  qtyUnit,
}: {
  qty: number;
  rate: number | string;
  qtyUnit: string | number;
}) => {
  let total = 0;
  if (qtyUnit == "MT/TON") {
    total = Number(qty) * 1000 * Number(rate);
  } else if (qtyUnit == "QUINTAL") {
    total = Number(qty) * 100 * Number(rate);
  } else {
    total = Number(qty) * Number(rate);
  }
  return parseFloat(total.toFixed(2));
};
let calculateTimer: any = null;
const EditPostRequest: FC<{
  onModalClose: any;
  type: string;
}> = ({ onModalClose, type }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const {
    details,
    handleDateSelection,
    setProposalDetails,
    submit,
    selectedDate,
    supplyDetailsById,
  } = useProposalEdit({
    id: parseInt(params.postId || "0"),
    modalClose: onModalClose,
    type: type,
  });
  const [totalVal, setTotal] = useState(0);
  const initCalculate = () => {
    if (calculateTimer) {
      clearTimeout(calculateTimer);
    }
    calculateTimer = setTimeout(() => {
      let total = calculate({
        qty:
          typeof supplyDetailsById?.qty === "number"
            ? supplyDetailsById?.qty
            : 0,
        rate: details?.rate ? details?.rate : 0,
        qtyUnit: supplyDetailsById?.unitType || "",
      });
      setTotal(total);
      // setProposalDetails({
      //   ...details!,
      //   expTotal: total,
      // });
    }, 300);
  };
  useEffect(() => {
    initCalculate();
  }, [supplyDetailsById?.qty, details?.rate]);
  useEffect(() => {
    let daysVal;

    if (details?.validity === "Next 1 days") {
      daysVal = 1;
    } else if (details?.validity === "Next 2 days") {
      daysVal = 2;
    } else if (details?.validity === "Next 3 days") {
      daysVal = 3;
    } else if (details?.validity === "Next 4 days") {
      daysVal = 4;
    } else {
      daysVal = 0;
    }

    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + daysVal);
    setProposalDetails({
      ...details!,
      offerValidity: moment(futureDate).format("YYYY-MM-DD") || "",
    });
  }, [details?.validity]);
  const calculateDateDifference = (daysFromToday: any) => {
    let daysVal;

    if (daysFromToday === "Next 1 days") {
      daysVal = 1;
    } else if (daysFromToday === "Next 2 days") {
      daysVal = 2;
    } else if (daysFromToday === "Next 3 days") {
      daysVal = 3;
    } else {
      daysVal = 0;
    }

    const today = new Date();
    const futureDate = new Date();
    futureDate.setDate(today.getDate() + daysVal);
    setProposalDetails({
      ...details!,
      offerValidity: moment(futureDate).format("YYYY-MM-DD") || "",
    });
    return futureDate;
  };
  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{ height: "calc(100vh - 22rem)" }}
      >
        <div className="pb-3 w-full flex gap-4">
          <div className="grow-[3]">
            <div
              className={`relative flex items-center bg-white shrink-0 px-2 py-2 border br-10`}
              style={{ borderRightWidth: "1px", height: "70px" }}
            >
              <div className={`flex items-center cursor-pointer w-full`}>
                <PartnerInfo
                  profilePic={details?.profilePic || ""}
                  name={details?.traderName || ""}
                  shortName={""}
                  partnerType={details?.tradeType}
                  partnerId={details?.traderId}
                  partnerMobile={details?.mobile || ""}
                  partyName={details?.addressLine}
                />
              </div>
            </div>
          </div>
          <div className="grow-[3]">
            <div className="grow overflow-auto scrollbar">
              <SelectCrop status="true" />
            </div>
          </div>
        </div>

        <div className="pt-4 w-full flex gap-4">
          <table>
            <tr>
              <th className="text-sm font-medium">Quantity*</th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={supplyDetailsById?.qty}
                  disabled={true}
                  fromSupplyTab={true}
                />
              </td>
            </tr>
            <tr>
              <th className="text-sm font-medium">Expected Rate* (Per KG)</th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={details?.rate}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setProposalDetails({
                        ...details!,
                        rate: "",
                      });
                  }}
                  onChange={(e) => {
                    setProposalDetails({
                      ...details!,
                      rate:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                />
              </td>
            </tr>
            <tr>
              <th
                className="text-sm font-medium"
                style={{ borderRight: "0px" }}
              ></th>
              <td className="text-sm font-medium" style={{ borderLeft: "0px" }}>
                Expected Total :{" "}
                <span className="color-primary">
                  {getCurrencyNumberWithSymbol(totalVal) || "0"}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div className="pt-4 w-full flex gap-4">
          <div className="grow-[3]" style={{ width: "50%" }}>
            <span className="text-sm">
              {type == "SUPPLY" ? "Expected" : "Available"} Date{" "}
              <span className="color-red">*</span>
            </span>
            <SelectDate
              onChange={handleDateSelection}
              selectedDate={selectedDate}
              maxDate={moment().add(2, "weeks").format("YYYY-MM-DD")}
            />
          </div>
          {/* {details?.validity} */}
          <div className="grow-[3]" style={{ width: "50%" }}>
            {type == "SUPPLY" && (
              <div className="flex flex-col gap-1">
                <span className="text-sm mb-2">
                  Offer Validity <span className="color-red">*</span>
                </span>
                <div className="">
                  <Select2
                    value={details?.validity}
                    options={[
                      { label: "Today Only", value: "Today only" },
                      {
                        label: "Next 1 Days",
                        value: "Next 1 days",
                      },
                      {
                        label: "Next 2 Days",
                        value: "Next 2 days",
                      },
                      {
                        label: "Next 3 Days",
                        value: "Next 3 days",
                      },
                      {
                        label: "Next 4 Days",
                        value: "Next 4 days",
                      },
                    ]}
                    onChange={(e) => {
                      setProposalDetails({
                        ...details!,
                        validity: e.value,
                      });
                      // calculateDateDifference(e.value);
                    }}
                    fromSupplyDemand={true}
                  />
                </div>
                <span className="text-xs">
                  Offer Validity Date :{" "}
                  {moment(details?.offerValidity).format("DD-MMM-YYYY")}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div className="flex items-center bottom-0 right-0 w-full justify-between bg-white py-4 border-top gap-2">
          <Button
            variant="primaryLight"
            className=""
            text="CANCEL"
            onClick={() => {
              onModalClose();
            }}
          />
          <Button
            variant="contained"
            className=""
            onClick={() => {
              submit();
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </>
  );
};
export default EditPostRequest;

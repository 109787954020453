import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import Compressor from "compressorjs";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosClient from "../../hooks/useAxiosClient";
import {
  getPaymentHistory,
  setSelectedDate,
  updateCropData,
  updateProgressDetails,
  updateTradeDetails,
  updateTradeId,
} from "../../reducers/tradeSlice";
import { TRootState } from "../../store";
import {
  TCrop,
  TPaymentHistory,
  TTradeCompleteDetai,
  TTradeDocument,
} from "../../types";
import useTrades from "../../hooks/useTrades";
const useOrderView = ({
  traderId,
  tradeId,
  activePaymentTab,
}: {
  traderId: number;
  tradeId: number;
  activePaymentTab?: string;
}) => {
  const { handleGetCall, handlePostCall, handlePutCall } = useAxiosClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    InitTradeDetail,
    adminId,
    paymentHistoryObj,
    paymentUpdateStatus,
    role,
    tententType,
  } = useSelector((state: TRootState) => ({
    InitTradeDetail: state.trades.tradeDetails,
    adminId: state.auth.adminId,
    paymentHistoryObj: state.trades.paymentHistoryObj,
    paymentUpdateStatus: state.trades.paymentUpdateStatus,
    role: state.auth.userInfo?.roleType,
    tententType: state.auth.userInfo?.type,
  }));
  const [tradeDetail, setTradeDetail] = useState<TTradeCompleteDetai | null>(
    InitTradeDetail
  );
  const [billCreationStatus, setBillCreationStatus] = useState<{
    status: "" | "PENDING" | "COMPLETED" | "LOADING";
  }>({ status: "PENDING" });
  const [editDetail, setEditDetail] = useState<{
    section: string;
    mode: "view" | "edit";
    type?: string;
  }>({ section: "", mode: "view", type: "" });
  // const acceptTradeRef: any = useRef(null);
  const [showCloseTradeAlert, setShowCloseTradeAlert] = useState(false);
  const [showStatusMessage, setshowStatusMessage] = useState("");
  const [showAcceptTradeModal, setShowAcceptTradeModal] = useState(false);
  const [documentVewer, setDocumentViewer] = useState<{
    open: boolean;
    url: string;
    title: string;
    docType: "image" | "pdf";
  }>({
    open: false,
    url: "",
    title: "",
    docType: "image",
  });
  const [acceptOtpNNumber, setAcceptOtpNumber] = useState<string>("");
  const [recordPaymentData, setRecordPaymentData] = useState<{
    partyId: number | string;
    paymentDate: string;
    paidAmount: number | "";
    // paymentType: "ADVANCE" | "INSTALLMENT" | "FINAL_PAYMENT" | "";
    paymentType: string | "";
    paymentMode: string;
    comments: string;
    paymentTypeVal: string | "";
    requestAdv: number | "";
  }>({
    partyId: "",
    paymentDate: paymentUpdateStatus
      ? moment(paymentHistoryObj?.date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
    paidAmount: paymentUpdateStatus ? paymentHistoryObj?.amount || "" : "",
    paymentType: paymentUpdateStatus
      ? paymentHistoryObj?.pmtType.toUpperCase() == "PARTIAL PAYMENT"
        ? "INSTALLMENT"
        : paymentHistoryObj?.pmtType.toUpperCase() || ""
      : "",
    paymentMode: paymentUpdateStatus ? paymentHistoryObj?.pmtMode || "" : "",
    comments: paymentUpdateStatus ? paymentHistoryObj?.comments || "" : "",
    paymentTypeVal: paymentUpdateStatus
      ? paymentHistoryObj?.inOutWard || ""
      : "",
    requestAdv: paymentUpdateStatus
      ? paymentHistoryObj?.amount || ""
      : tradeDetail?.reqAdv?.requestAdv || "",
  });
  const [invoiceDocuments, setInvoiceDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [receiptsDocuments, setReceiptsDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [otherDocuments, setOtherDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [arivalDocuments, setArivalDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [arrivalWeightDocuments, setArrivalWeightDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [pattiDocuments, setPattiDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const [paymentDocuments, setPaymentDocuments] = useState<
    Array<{ docType: "image" | "pdf"; url: string }>
  >([]);
  const openInFullScreen = (
    url: string,
    type: string,
    docType: "image" | "pdf"
  ) => {
    setDocumentViewer({ open: true, url: url, title: type, docType: docType });
  };
  const calculateAdvancePaid = (history: any) => {
    let sum = 0;
    if (history.length > 0) {
      for (let item of history) {
        if (
          item.pmtType.toUpperCase() == "ADVANCE" &&
          item.inOutWard.toUpperCase() == "INWARD"
        ) {
          sum += item.amount;
        }
      }
    }
    return sum;
  };
  const calculateAdvancePaidSrc = (history: any) => {
    let sum = 0;
    if (history.length > 0) {
      for (let item of history) {
        if (
          item.pmtType.toUpperCase() == "ADVANCE" &&
          item.inOutWard.toUpperCase() == "OUTWARD"
        ) {
          sum += item.amount;
        }
      }
    }
    return sum;
  };
  const getTradeDetail = () => {
    handleGetCall<TTradeCompleteDetai>(
      `/admin/trade-platform/trades/adminId/${adminId}/traderId/${traderId}/tradeId/${tradeId}`
    )
      .then(({ data }) => {
        let invoices: TTradeDocument[] = [];
        let recipts: TTradeDocument[] = [];
        let others: TTradeDocument[] = [];
        let arrivals: TTradeDocument[] = [];
        let patti: TTradeDocument[] = [];
        let payment: TTradeDocument[] = [];
        let outWard: TTradeDocument[] = [];
        let arrivalWeight: TTradeDocument[] = [];
        let cropsData: TCrop;
        let outwardHistory: TPaymentHistory[] = [];
        let inwardHistory: TPaymentHistory[] = [];
        let advReqImages: TTradeDocument[] = [];
        let cropsDataArray: TCrop[] = [];
        if (data.images != null && data.images.length > 0) {
          for (let image of data.images) {
            let docType: "pdf" | "image" = image.url.includes(".pdf")
              ? "pdf"
              : "image";
            if (image.type === "tapal") {
              invoices.push({ ...image, docType: docType });
            } else if (image.type === "receipt") {
              recipts.push({ ...image, docType: docType });
            } else if (image.type === "other") {
              others.push({ ...image, docType: docType });
            } else if (image.type === "arrival") {
              arrivals.push({ ...image, docType: docType });
            } else if (image.type === "patti") {
              patti.push({ ...image, docType: docType });
            } else if (image.type === "arrivalWight") {
              arrivalWeight.push({ ...image, docType: docType });
            } else if (
              image.type === "payment" ||
              image.type === "PMT_INWARD" ||
              image.type === "PMT_OUTWARD"
            ) {
              payment.push({ ...image, docType: docType });
            } else if (image.type === "PMT_OUTWARD") {
              outWard.push({ ...image, docType: docType });
            }
            // else if (image.type === "advReq") {
            //   advReqImages.push({ ...image, docType: docType });
            // }
          }
        }
        if (data.reqAdv != null) {
          if (data.reqAdv.images != null && data.reqAdv.images.length > 0) {
            for (let imag of data.reqAdv.images) {
              let docType: "pdf" | "image" = imag.url.includes(".pdf")
                ? "pdf"
                : "image";
              advReqImages.push({ ...imag, docType: docType });
            }
          }
        }
        if (data.pmtHistory.length > 0) {
          for (let item of data.pmtHistory) {
            var obj = { ...item };
            let img: TTradeDocument[] = [];
            Object.assign(obj, {
              images: img,
            });
            if (obj.inOutWard == "OUTWARD") {
              for (let j of payment) {
                if (j.typeId == obj.id) {
                  obj.images.push(j);
                }
              }
              outwardHistory.push(obj);
            } else {
              for (let j of payment) {
                if (j.typeId == obj.id) {
                  obj.images.push(j);
                }
              }
              inwardHistory.push(obj);
            }
          }
        }

        let selectedParty = {
          addrLine: data.destAddrLine,
          altMobile: data.destAltMobile,
          city: data.destCity,
          traderId: data.destPartyId,
          marketName: data.destMarketName,
          mobile: data.destMobile,
          name: data.destName,
          shopNum: data.destShopNum,
          tradeName: data.destTradeName,
          relMgr: "",
          rmMobile: "",
          status: "",
        };
        let selectedSrcParty = {
          addrLine: data.srcAddrLine,
          altMobile: data.srcAltMobile,
          city: data.srcCity,
          traderId: data.srcPartyId,
          marketName: data.srcMarketName,
          mobile: data.srcMobile,
          name: data.srcName,
          shopNum: data.srcShopNum,
          tradeName: data.srcTradeName,
          relMgr: "",
          rmMobile: "",
          status: "",
        };
        let loss =
          data?.lineItems.length > 0
            ? data?.lineItems[0].destWeight
              ? Number(data?.lineItems[0].weight) -
                Number(data?.lineItems[0].destWeight)
              : 0
            : 0;
        if (data.lineItems.length > 0) {
          for (let item of data.lineItems) {
            let weightSrc = item.weight;
            let weightDest = item.destWeight;
            if (
              item.weightUnit == "RATE_PER_KG" &&
              item.weight &&
              tententType == "BAYER"
            ) {
              if (item.unitType == "QUINTAL") {
                weightSrc = item.weight * 100;
                weightDest = Number(item.destWeight) * 100;
              } else if (item.unitType == "MT/TON") {
                weightSrc = item.weight * 1000;
                weightDest = Number(item.destWeight) * 1000;
              } else {
                weightSrc = item.weight;
                weightDest = item.destWeight;
              }
              console.log(weightSrc, "we");
            }
            cropsData = {
              baseName: item.baseName,
              cropId: item.cropId,
              cropName: item.cropName,
              imageUrl: item.imageUrl,
              type: item.type,
              units: item.units,
              varietyName: item.varietyName,
              qtyUnit: item.qtyUnit,
              rateType: item.weightUnit,
              qty: item.qty,
              weight: item.weight,
              freightAmt: item.freightAmt,
              status: 2,
              tradeId: item.tradeId,
              weightUnit:
                item.weightUnit == "KGS" ? "RATE_PER_KG" : item.weightUnit,
              id: item.id,
              addWastage: item.addWastage,
              destWeight: item.destWeight,
              destRate: item.destRate,
              rate: item.rate,
              unitType: item.unitType,
              destBayerWeight: item.destWeight || 0,
              bayerWeight:
                tententType == "BAYER" ? weightSrc : item.weight || 0,
            };
            loss =
              data?.lineItems.length > 0
                ? item.destWeight
                  ? Number(tententType == "BAYER" ? weightSrc : item.weight) -
                    Number(item.destWeight)
                  : 0
                : 0;
            cropsDataArray.push(cropsData);
            dispatch(
              updateCropData({
                selectedParty: selectedParty,
                selectedCrop: cropsData,
                selectedSrcParty: selectedSrcParty,
              })
            );
          }
        } else {
          dispatch(
            updateCropData({
              selectedParty: selectedParty,
              selectedCrop: null,
              selectedSrcParty: selectedSrcParty,
            })
          );
        }
        dispatch(setSelectedDate(data.date));
        let tradeAcceptedTime = "";
        if (data.progress && data.progress.length > 0) {
          for (let pg of data.progress) {
            if (pg.progState === "ACCEPTED") {
              tradeAcceptedTime = pg.date;
            }
          }
        }
        let tradeCommentsVal = "";
        if (data.progress.length > 0) {
          for (let status of data.progress!) {
            if (status.progState == "PENDING") {
              tradeCommentsVal = status.comments;
            }
          }
        }
        let receiveable =
          data.tradeMode == "COMM"
            ? Number(data?.pattiAmt) > 0 &&
              Number(data?.pattiAmt) -
                (Number(data?.freightPaid) +
                  Number(data?.comm) +
                  Number(data?.lbrCharges) +
                  calculateAdvancePaidSrc(data.pmtHistory) +
                  Number(data?.misc))
            : Number(data?.invAmt) -
              (Number(data?.freightPaid) +
                calculateAdvancePaidSrc(data.pmtHistory) +
                (Number(data?.srcDisc) || 0));
        let payable =
          data.tradeMode == "COMM"
            ? Number(data?.pattiAmt) > 0 &&
              Number(data?.pattiAmt) -
                (Number(data?.freightPaid) +
                  Number(data?.comm) +
                  Number(data?.lbrCharges) +
                  calculateAdvancePaid(data.pmtHistory) +
                  Number(data?.misc))
            : Number(data?.invAmt) -
              (Number(data?.freightPaid) +
                calculateAdvancePaid(data.pmtHistory) +
                (Number(data?.destDisc) || 0));

        let finalPay =
          Number(payable) + Number(data?.destPf) - Number(data?.destPfDisc);

        let finalReci =
          Number(receiveable) -
          // (data.pmtHistory.length > 0
          //   ? calculateAdvancePaidSrc(data?.pmtHistory)
          //   : 0) -
          Number(data?.srcPf) +
          Number(data?.srcPfDisc);
        const unit_price_dest =
          (Number(data?.destPf) - Number(data?.destPfDisc)) / 1.18;
        const unit_price_src =
          (Number(data?.srcPf) - Number(data?.srcPfDisc)) / 1.18;

        setTradeDetail({
          ...data,
          invoices: invoices,
          receipts: recipts,
          otherDocumets: others,
          selectedTrader: selectedParty,
          lineItems: cropsDataArray.length ? cropsDataArray : data.lineItems,
          arrivalDocs: arrivals,
          arrivalWeightDocs: arrivalWeight,
          pattiDocs: patti,
          paymentDocs:
            activePaymentTab == "outward_payment" ? outWard : payment,
          tradeAcceptedTime: tradeAcceptedTime,
          pmtHistory: data.pmtHistory,
          tradecomments: tradeCommentsVal,
          totalReceivable: receiveable || 0,
          moistureLoss: loss,
          advancePaid: calculateAdvancePaid(data.pmtHistory),
          advanceRcvd: calculateAdvancePaidSrc(data.pmtHistory),
          destDis: data.destPfDisc,
          srcDis: data.srcPfDisc,
          totalPayable: payable || 0,
          reqAdv: data?.reqAdv,
          commodityCost: data.cmdityCost || 0,
          reqAdvanceDocuments: advReqImages,
          srcTradeStatus: data?.srcTradeStatus,
          tradeStatus: data?.destTradeStatus,
          finalPayable: finalPay,
          finalReceivable: finalReci,
          unitPriceSrc: unit_price_src.toFixed(2),
          totalAmountSrc: parseFloat(unit_price_src.toFixed(2)),
          gstAmountSrc: parseFloat(unit_price_src.toFixed(2)) * 0.18,
          payableAfterGstSrc: Number(data?.srcPf) - Number(data?.srcPfDisc),
          // tradeInvoiceObj: invoiceTradeObj,
          destTax: data.destTax,
          srcTax: data.srcTax,
          srcInvId: data.srcInvId,
          destInvId: data.destInvId,
          srcPfId: data.srcPfId,
          destPfId: data.destPfId,
          unitPriceDest: unit_price_dest.toFixed(2),
          totalAmountDest: parseFloat(unit_price_dest.toFixed(2)),
          gstAmountDest: parseFloat(unit_price_dest.toFixed(2)) * 0.18,
          payableAfterGstDest: Number(data?.destPf) - Number(data?.destPfDisc),
        });
        dispatch(
          updateTradeDetails({
            tradeDetails: {
              ...data,
              invoices: invoices,
              receipts: recipts,
              otherDocumets: others,
              selectedTrader: selectedParty,
              lineItems:
                cropsDataArray.length > 0 ? cropsDataArray : data.lineItems,
              progress: data.progress,
              arrivalDocs: arrivals,
              arrivalWeightDocs: arrivalWeight,
              pattiDocs: patti,
              paymentDocs:
                activePaymentTab == "outward_payment" ? outWard : payment,
              tradeAcceptedTime: tradeAcceptedTime,
              advancePaid: calculateAdvancePaid(data.pmtHistory),
              advanceRcvd: calculateAdvancePaidSrc(data.pmtHistory),
              destPmtTerm: data.destPmtTerm
                ? data.destPmtTerm.toString()
                : data.destPmtTerm,
              delayedChargesVal: data.destDelayCharges,
              srcPmtTerm: data.srcPmtTerm
                ? data.srcPmtTerm.toString()
                : data.srcPmtTerm,
              destDis: data.destPfDisc,
              srcDis: data.srcPfDisc,
              tradecomments: tradeCommentsVal,
              totalReceivable: receiveable || 0,
              moistureLoss: loss,
              pmtHistory: data.pmtHistory,
              srcPmtHistory: outwardHistory,
              destPmtHistory: inwardHistory,
              totalPayable: payable || 0,
              destDisc: data.destDisc || 0,
              srcDisc: data.srcDisc || 0,
              destVehNum: data.destVehNum || "",
              srcVehNum: data.srcVehNum || "",
              reqAdv: data?.reqAdv,
              commodityCost: data.cmdityCost || 0,
              reqAdvanceDocuments: advReqImages,
              srcTradeStatus: data?.srcTradeStatus,
              tradeStatus: data?.destTradeStatus,
              finalPayable: finalPay,
              finalReceivable: finalReci,
              unitPriceSrc: unit_price_src.toFixed(2),
              totalAmountSrc: parseFloat(unit_price_src.toFixed(2)),
              gstAmountSrc: parseFloat(unit_price_src.toFixed(2)) * 0.18,
              payableAfterGstSrc: Number(data?.srcPf) - Number(data?.srcPfDisc),
              // tradeInvoiceObj: invoiceTradeObj,
              destTax: data.destTax,
              srcTax: data.srcTax,
              srcInvId: data.srcInvId,
              destInvId: data.destInvId,
              srcPfId: data.srcPfId,
              destPfId: data.destPfId,
              unitPriceDest: unit_price_dest.toFixed(2) || 0,
              totalAmountDest: parseFloat(unit_price_dest.toFixed(2)),
              gstAmountDest: parseFloat(unit_price_dest.toFixed(2)) * 0.18,
              payableAfterGstDest:
                Number(data?.destPf) - Number(data?.destPfDisc),
              destTradeStatus: data?.destTradeStatus,
            },
          })
        );
      })
      .catch((err: any) => {
        console.log(err, "err");
        toast.error(err.response.data.status.message);
        if (err.response.data.status.message == "INVALID_INPUT") {
          setshowStatusMessage(
            "You are an unauthorized user to view the trade details."
          );
        }
      });
  };
  const onUploadReceiptImage = async (
    file: any,
    docymentType:
      | "invoice"
      | "receipt"
      | "other"
      | "arival"
      | "patti"
      | "arrivalWight"
      | "payment" = "other"
  ) => {
    let fileType = "";
    if (file.type === "application/pdf") {
      fileType = "pdf";
    } else if (file.type.includes("image/")) {
      fileType = "image";
    }
    let formdata = new FormData();
    formdata.append("traderId", traderId.toString());
    if (fileType === "pdf") {
      formdata.append("file", file, file.name.replace(" ", "-"));
      let imageUploadResponse = await handlePostCall<string[]>({
        URL: "/mandi/files/trade-portal/imgs/upload",
        apiParams: formdata,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      });
      let url = imageUploadResponse.data[0];
      if (docymentType === "invoice") {
        setInvoiceDocuments([
          { docType: "pdf", url: url },
          ...invoiceDocuments,
        ]);
      } else if (docymentType === "receipt") {
        setReceiptsDocuments([
          { docType: "pdf", url: url },
          ...receiptsDocuments,
        ]);
      } else if (docymentType === "arival") {
        setArivalDocuments([{ docType: "pdf", url: url }, ...arivalDocuments]);
      } else if (docymentType === "arrivalWight") {
        setArrivalWeightDocuments([
          { docType: "pdf", url: url },
          ...arrivalWeightDocuments,
        ]);
      } else if (docymentType === "patti") {
        setPattiDocuments([{ docType: "pdf", url: url }, ...pattiDocuments]);
      } else if (docymentType === "payment") {
        setPaymentDocuments([
          { docType: "pdf", url: url },
          ...paymentDocuments,
        ]);
      } else {
        setOtherDocuments([{ docType: "pdf", url: url }, ...otherDocuments]);
      }
    } else {
      new Compressor(file, {
        quality: 0.8,
        success: async (compressedResult) => {
          formdata.append(
            "file",
            compressedResult,
            file.name.replace(" ", "-")
          );
          let imageUploadResponse = await handlePostCall<string[]>({
            URL: "/mandi/files/trade-portal/imgs/upload",
            apiParams: formdata,
            config: {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          });
          let url = imageUploadResponse.data[0];
          if (docymentType === "invoice") {
            setInvoiceDocuments([
              { docType: "image", url: url },
              ...invoiceDocuments,
            ]);
          } else if (docymentType === "receipt") {
            setReceiptsDocuments([
              { docType: "image", url: url },
              ...receiptsDocuments,
            ]);
          } else if (docymentType === "arival") {
            setArivalDocuments([
              { docType: "image", url: url },
              ...arivalDocuments,
            ]);
          } else if (docymentType === "arrivalWight") {
            setArrivalWeightDocuments([
              { docType: "image", url: url },
              ...arrivalWeightDocuments,
            ]);
          } else if (docymentType === "patti") {
            setPattiDocuments([
              { docType: "image", url: url },
              ...pattiDocuments,
            ]);
          } else if (docymentType === "payment") {
            setPaymentDocuments([
              { docType: "image", url: url },
              ...paymentDocuments,
            ]);
          } else {
            setOtherDocuments([
              { docType: "image", url: url },
              ...otherDocuments,
            ]);
          }
        },
      });
    }
  };
  const onRemoveReceiptImage = (
    index: number,
    isExist: boolean = false,
    docymentType:
      | "invoice"
      | "receipt"
      | "other"
      | "arival"
      | "patti"
      | "arrivalWight"
      | "payment" = "other",
    id?: number
  ) => {
    if (isExist === true && tradeDetail !== null) {
      if (
        docymentType == "payment" &&
        paymentHistoryObj != null &&
        paymentUpdateStatus
      ) {
        let invoicesObj = { ...paymentHistoryObj };
        let invoices = [...paymentHistoryObj?.images];

        let i = invoices.findIndex((img) => img.id === id);

        if (i != -1) {
          invoices[i] = { ...invoices[i], status: 0 };
          invoicesObj.images = invoices;
          dispatch(
            getPaymentHistory({
              paymentHistoryObj: invoicesObj,
            })
          );
        }

        // setTradeDetail({ ...tradeDetail, invoices: invoices });
        // setTapalDelInvoice(invoices);
      } else {
        let invoices = [...tradeDetail.otherDocumets];
        let i = invoices.findIndex((img) => img.id === index);
        invoices[i] = { ...invoices[i], status: 0 };
        setTradeDetail({ ...tradeDetail, otherDocumets: invoices });
      }
    } else {
      if (docymentType === "invoice") {
        let newtapalInvoice = [...invoiceDocuments];
        newtapalInvoice.splice(index, 1);
        setInvoiceDocuments(newtapalInvoice);
      } else if (docymentType === "receipt") {
        let newtapalInvoice = [...receiptsDocuments];
        newtapalInvoice.splice(index, 1);
        setReceiptsDocuments(newtapalInvoice);
      } else if (docymentType === "arival") {
        let newtapalInvoice = [...arivalDocuments];
        newtapalInvoice.splice(index, 1);
        setArivalDocuments(newtapalInvoice);
      } else if (docymentType === "arrivalWight") {
        let newtapalInvoice = [...arrivalWeightDocuments];
        newtapalInvoice.splice(index, 1);
        setArrivalWeightDocuments(newtapalInvoice);
      } else if (docymentType === "patti") {
        let newtapalInvoice = [...pattiDocuments];
        newtapalInvoice.splice(index, 1);
        setPattiDocuments(newtapalInvoice);
      } else if (docymentType === "payment") {
        let newtapalInvoice = [...paymentDocuments];
        newtapalInvoice.splice(index, 1);
        setPaymentDocuments(newtapalInvoice);
      } else {
        let newtapalInvoice = [...otherDocuments];
        newtapalInvoice.splice(index, 1);
        setOtherDocuments(newtapalInvoice);
      }
    }
  };
  const saveTradeDetail = () => {
    let images = [];
    if (invoiceDocuments.length) {
      for (let doc of invoiceDocuments) {
        images.push({
          addedBy: adminId,
          id: 0,
          imgFrom: "TP_TRD",
          reqId: tradeId,
          status: 1,
          type: "tapal",
          url: doc.url,
          tradeId: tradeId,
        });
      }
    }
    if (receiptsDocuments.length) {
      for (let doc of receiptsDocuments) {
        images.push({
          addedBy: adminId,
          id: 0,
          imgFrom: "TP_TRD",
          reqId: tradeId,
          status: 1,
          type: "receipt",
          url: doc.url,
          tradeId: tradeId,
        });
      }
    }
    if (arivalDocuments.length) {
      for (let doc of arivalDocuments) {
        images.push({
          addedBy: adminId,
          id: 0,
          imgFrom: "TP_TRD",
          reqId: tradeId,
          status: 1,
          type: "arrival",
          url: doc.url,
          tradeId: tradeId,
        });
      }
    }
    if (arrivalWeightDocuments.length) {
      for (let doc of arrivalWeightDocuments) {
        images.push({
          addedBy: adminId,
          id: 0,
          imgFrom: "TP_TRD",
          reqId: tradeId,
          status: 1,
          type: "arrivalWight",
          url: doc.url,
          tradeId: tradeId,
        });
      }
    }
    if (pattiDocuments.length) {
      for (let doc of pattiDocuments) {
        images.push({
          addedBy: adminId,
          id: 0,
          imgFrom: "TP_TRD",
          reqId: tradeId,
          status: 1,
          type: "patti",
          url: doc.url,
          tradeId: tradeId,
        });
      }
    }
    if (paymentDocuments.length) {
      for (let doc of paymentDocuments) {
        images.push({
          addedBy: adminId,
          id: 0,
          imgFrom: "TP_TRD",
          reqId: tradeId,
          status: 1,
          type: "payment",
          url: doc.url,
          tradeId: tradeId,
        });
      }
    }
    if (otherDocuments.length) {
      for (let doc of otherDocuments) {
        images.push({
          addedBy: adminId,
          id: 0,
          imgFrom: "TP_TRD",
          reqId: tradeId,
          status: 1,
          type: "other",
          url: doc.url,
          tradeId: tradeId,
        });
      }
    }
    handlePutCall({
      URL: "/admin/trade-platform/trades/metadata",
      apiParams: {
        adminId: adminId,
        images: images,
        tradeId: tradeId,
      },
    }).then(() => {
      toast.success("Updated successfully");
      setEditDetail({ section: "", mode: "view" });
      getTradeDetail();
      if (invoiceDocuments.length) {
        setInvoiceDocuments([]);
      }
      if (receiptsDocuments.length) {
        setReceiptsDocuments([]);
      }
      if (otherDocuments.length) {
        setOtherDocuments([]);
      }
    });
  };
  useEffect(() => {
    setRecordPaymentData({
      ...recordPaymentData,
      paidAmount: recordPaymentData?.paidAmount,
      requestAdv:
        recordPaymentData?.paymentType == "ADVANCE"
          ? recordPaymentData?.paidAmount
          : recordPaymentData?.requestAdv,
    });
  }, [recordPaymentData?.paidAmount, recordPaymentData?.paymentType]);
  useEffect(() => {
    if (
      recordPaymentData?.paymentType == "ADVANCE" &&
      recordPaymentData?.paymentTypeVal == "OUTWARD" &&
      tradeDetail?.reqAdv?.verified == 1 &&
      recordPaymentData?.paidAmount == 0
    ) {
      setRecordPaymentData({
        ...recordPaymentData,
        paidAmount: recordPaymentData?.paidAmount,
      });
    }
  }, [recordPaymentData?.paymentType]);
  const submitRecordPayment = (
    closeRecordPaymentPanel: (paymentDone: boolean) => void
  ) => {
    setBillCreationStatus({ ...billCreationStatus, status: "LOADING" });
    if (!recordPaymentData.partyId) {
      toast.error("Select Party");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (!recordPaymentData.paidAmount) {
      toast.error("Enter Paid/Received amount");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    if (recordPaymentData.paymentType == "") {
      toast.error("Please select payment type");
      setBillCreationStatus({ ...billCreationStatus, status: "" });
      return;
    }
    // if (tradeDetail?.totalReceivable! > 0) {
    //   if (recordPaymentData.paidAmount > tradeDetail?.totalReceivable!) {
    //     toast.error(
    //       "Entered Paid/Received amount should not more than Total Payable"
    //     );
    //     setBillCreationStatus({ ...billCreationStatus, status: "" });
    //     return;
    //   }
    // }
    // const paymentType: "INWARD" | "OUTWARD" | "" =
    //   recordPaymentData.partyId === tradeDetail?.destPartyId
    //     ? "INWARD"
    //     : recordPaymentData.partyId === tradeDetail?.srcPartyId
    //     ? "OUTWARD"
    //     : "";
    let imgs = [];
    if (paymentUpdateStatus) {
      if (paymentHistoryObj?.images) {
        for (let image of paymentHistoryObj.images) {
          if (image.status == 0) {
            imgs.push({
              ...image,
              // tradeId: tradeDetail.tradeId,
              addedBy: adminId,
              typeId: paymentUpdateStatus ? paymentHistoryObj?.id : 0,
              type: paymentUpdateStatus
                ? "PMT_" + paymentHistoryObj?.inOutWard
                : "PMT_" + recordPaymentData.paymentTypeVal,
            });
          }
        }
      }
    }
    if (otherDocuments.length > 0) {
      for (let file of otherDocuments) {
        imgs.push({
          id: 0,
          imgFrom: "TP_TRD",
          reqId: tradeId,
          status: 1,
          tradeId: tradeId,
          type: paymentUpdateStatus
            ? "PMT_" + paymentHistoryObj?.inOutWard
            : "PMT_" + recordPaymentData.paymentTypeVal,
          url: file.url,
          addedBy: adminId,
          typeId: paymentUpdateStatus ? paymentHistoryObj?.id : 0,
        });
      }
    }
    handlePostCall({
      URL: "admin/trade-platform/trades/payments",
      apiParams: {
        action: paymentUpdateStatus ? "UPDATE" : "",
        adminId: adminId,
        amount: recordPaymentData?.paidAmount,
        date: moment(recordPaymentData.paymentDate).format("YYYY-MM-DD"),
        images: imgs,
        // otherDocuments.map((doc) => {
        //   return {
        //     id: 0,
        //     imgFrom: "TP_TRD",
        //     reqId: tradeId,
        //     status: 1,
        //     tradeId: tradeId,
        //     type: paymentUpdateStatus
        //       ? "PMT_" + paymentHistoryObj?.inOutWard
        //       : "PMT_" + recordPaymentData.paymentTypeVal,
        //     url: doc.url,
        //     addedBy: adminId,
        //     typeId: paymentUpdateStatus ? paymentHistoryObj?.id : 0,
        //   };
        // }),
        inOutWard: paymentUpdateStatus
          ? paymentHistoryObj?.inOutWard
          : recordPaymentData.paymentTypeVal,
        pmtMode: recordPaymentData.paymentMode,
        pmtType: recordPaymentData.paymentType,
        tradeId: tradeId,
        traderId: recordPaymentData.partyId,
        id: paymentUpdateStatus ? paymentHistoryObj?.id : 0,
        status: paymentUpdateStatus ? 2 : 1,
        comments: recordPaymentData.comments,
        reqAdv: {
          advApproved: adminId,
          advDisb:
            tradeDetail?.reqAdv?.advDisb != 0 &&
            recordPaymentData?.paymentType != "ADVANCE"
              ? tradeDetail?.reqAdv?.advDisb
              : recordPaymentData?.requestAdv,
          disbDate: moment(recordPaymentData.paymentDate).format("YYYY-MM-DD"),
          tradeId: tradeId,
        },
      },
    })
      .then(({ data }) => {
        toast.success("updated successfully");
        setRecordPaymentData({
          ...recordPaymentData,
          partyId: "",
          paymentDate: moment().format("YYYY-MM-DD"),
          paidAmount: "",
          paymentType: "",
          paymentMode: "",
        });
        setOtherDocuments([]);
        closeRecordPaymentPanel(true);
        navigate(
          `/trade-detail/${tradeDetail?.srcPartyId}/${tradeDetail?.tradeId}?tab=OPEN`
        );
        // getTradeDetail();
      })
      .catch(() => {
        toast.error("something went wrong");
        setBillCreationStatus({ ...billCreationStatus, status: "" });
      });
  };

  //  const {trades, refresh, setRefresh} = useTrades({
  //       status: "OPEN",
  //       fromDate:"",
  //       toDate:"",
  //     })
  //     console.log(trades);

  const onChangeTradeStatus = (showAlert: boolean, status: string) => {
    if (showAlert === true) {
      setShowCloseTradeAlert(true);
      return;
    }
    if (status == "CLOSED") {
      if (tradeDetail != null) {
        if (
          tradeDetail?.inwardPmt < tradeDetail?.finalPayable ||
          tradeDetail?.outwardPmt < tradeDetail?.finalReceivable
        ) {
          toast.error(
            "To close the trade, Please make sure that the both Payable / Receivable are completed"
          );
          return;
        }
        // if (tradeDetail?.outwardPmt <= tradeDetail?.finalRec) {
        //   toast.error("Something went wrong");
        //   return;
        // }
      }
    }
    handlePutCall({
      URL: `admin/trade-platform/trades/tradeId/${tradeId}`,
      apiParams: {
        adminId: adminId,
        tradeId: tradeId,
        destTradeStatus: status,
        type: activePaymentTab == "inward_payment" ? "DEST" : "SOURCE",
      },
    })
      .then(() => {
        toast.success("Updated successfully");
        setShowCloseTradeAlert(false);
        getTradeDetail();
        // setRefresh(true);
        window.location.reload();
      })
      .catch(() => {
        toast.error("Something went wrong");
      });
  };
  return {
    tradeDetail,
    documentVewer,
    setDocumentViewer,
    openInFullScreen,
    setAcceptOtpNumber,
    getTradeDetail,
    setShowAcceptTradeModal,
    showAcceptTradeModal,
    showStatusMessage,
    onUploadReceiptImage,
    invoiceDocuments,
    receiptsDocuments,
    otherDocuments,
    arivalDocuments,
    arrivalWeightDocuments,
    pattiDocuments,
    paymentDocuments,
    onRemoveReceiptImage,
    setTradeDetail,
    recordPaymentData,
    setRecordPaymentData,
    submitRecordPayment,
    onChangeTradeStatus,
    showCloseTradeAlert,
    setShowCloseTradeAlert,
    saveTradeDetail,
    editDetail,
    setEditDetail,
    paymentHistoryObj,
    paymentUpdateStatus,
    billCreationStatus,
    role,
    tententType,
  };
};
export default useOrderView;

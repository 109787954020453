import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TTrader } from "../types/index";
import { TRootState } from "../store";

import {
  setSelectedStatus,
  updateTraderId,
  updateTraders,
} from "../reducers/tradeSlice";
const useAllTraders = () => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, role, traders, tententType } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      traders: state.trades.allTraders,
      tententType: state.auth.userInfo?.type,
    })
  );
  // const [traders, setTraders] = useState<TTrader[]>([]);
  const [onRefresh, setOnRefresh] = useState(false);

  const dispatch = useDispatch();
  const getAllTraders = () => {
    handleGetCall<TTrader[]>(
      `admin/trade-platform/traders/adminId/${adminId}?tenant=${tententType}`
    ).then(({ data }) => {
      if (data) {
        // setTraders(data);
        dispatch(
          updateTraders({
            allTraders: data,
          })
        );
      } else {
        // setTraders([]);
        dispatch(
          updateTraders({
            allTraders: [],
          })
        );
      }
    });
  };

  // useEffect(() => {
  //   handleGetCall<TTrader[]>(
  //     `admin/trade-platform/traders/adminId/${adminId}`
  //   ).then(({ data }) => {
  //     if (data) {
  //       setTraders(data);
  //     } else {
  //       setTraders([]);
  //     }
  //   });
  // }, []);
  return { traders, getAllTraders, onRefresh, setOnRefresh, role };
};
export default useAllTraders;

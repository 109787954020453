import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAxiosClient from "../../hooks/useAxiosClient";
import { TRootState } from "../../store";
import { TCrop, TSupplyDemandReq } from "../../types";
import {
  updateCropData,
  updateDemandDetails,
  updateSupplyDetails,
} from "../../reducers/tradeSlice";

const useDemandDetailsById = ({ id }: { id: number }) => {
  const { handleGetCall, handlePostCall } = useAxiosClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { adminId, role, InitDemandDetail } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      InitDemandDetail: state.trades.demandDetailsById,
    })
  );
  const [demandDetails, setDemandDetails] = useState<TSupplyDemandReq | null>(
    InitDemandDetail
  );

  const getDemandDetail = () => {
    handleGetCall<TSupplyDemandReq>(
      `admin/demand-supply/demandId/${id}/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        let cropsData: TCrop;
        let selectedParty = {
          addrLine: data?.addressLine,
          altMobile: data?.altMobile,
          city: data?.city,
          traderId: data?.traderId,
          marketName: "",
          mobile: data?.mobile,
          name: data?.traderName,
          shopNum: "",
          tradeName: "",
          relMgr: "",
          rmMobile: "",
          status: "",
        };
        if (data.cropName) {
          cropsData = {
            baseName: "",
            cropId: data?.cropId || 0,
            cropName: data?.cropName || "",
            imageUrl: data?.cropUrl || "",
            type: "",
            units: "",
            varietyName: "",
            qtyUnit: "",
            rateType: "",
            qty: data?.qty || "",
            weight: "",
            freightAmt: "",
            status: 1,
            tradeId: 0,
            weightUnit: "",
            id: 0,
            addWastage: "",
            destWeight: "",
            destRate: "",
            rate: data?.expRate || "",
            unitType: "",
          };
          dispatch(
            updateCropData({
              //   selectedParty: selectedParty,
              selectedCrop: cropsData,
            })
          );
        } else {
          dispatch(
            updateCropData({
              //  selectedParty: selectedParty,
              selectedCrop: null,
            })
          );
        }
        setDemandDetails({
          ...data,
          //   totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
        });
        dispatch(
          updateDemandDetails({
            demandDetailsById: {
              ...data,
              //   totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
            },
          })
        );
      } else {
        setDemandDetails(null);
      }
    });
  };

  return { demandDetails, getDemandDetail, role };
};
export default useDemandDetailsById;

import { FaClock, FaXmark } from "react-icons/fa6";
import { FC, useContext, useState, ChangeEvent, useEffect } from "react";
// import "../style.module.scss";
import useArrivalsView from "./useArrivalsView";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
  getTimeformat12,
} from "../../helpers/get-currency-number";
import SelectDate from "../../components/ui/select-date";
import { Button, FileUploader, Input } from "../../components/ui";
import DocumentViewer from "../../components/commons/document-preview";
import Modal from "../../components/ui/modal";
import ModalHeader from "../../components/ui/modal/modal-header";
import TimePicker from "../../components/commons/time-picker";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { TRootState } from "../../store";
const ArrivalConfirmation: FC<{
  editStatus: string;
  onArrivalModalClose: any;
  activeTabFun: any;
}> = ({ editStatus, onArrivalModalClose, activeTabFun }) => {
  const params = useParams();
  const {
    onUploadReceiptImage,
    arrivalDocuments,
    onRemoveReceiptImage,
    alltradeDetail,
    setArrivalIInfo,
    handleDateSelection,
    confrirmArrival,
    arrivalIInfo,
    arrivalWeightDocuments,
    // timeChange,
    // value,
    handleTimeSelection,
  } = useArrivalsView({
    traderId: parseInt(params.traderId || "0"),
    tradeId: parseInt(params.tradeId || "0"),
    mode: editStatus == "edit" ? true : false,
    type: "ARRIVAL",
    closeModal: onArrivalModalClose,
    tabActive: activeTabFun,
  });
  const { tradeDetails } = useSelector((state: TRootState) => ({
    tradeDetails: state.trades.tradeDetails,
  }));
  const [timePickerModal, setTimePickerModal] = useState(false);
  useEffect(() => {
    console.log(editStatus, alltradeDetail);
    if (!editStatus) {
      if (alltradeDetail == null) {
        setArrivalIInfo({
          ...arrivalIInfo,
          freightPaid:
            tradeDetails?.freightPaid != 0
              ? tradeDetails?.freightPaid!
              : Number(tradeDetails?.freightAmt) -
                Number(tradeDetails?.freightAdv),
        });
      }
    }
  }, [editStatus]);
  const onChangeNetweight = (e: ChangeEvent<HTMLInputElement>) => {
    setArrivalIInfo({
      ...arrivalIInfo,
      destWastage: Number(alltradeDetail?.destWeight) - Number(e.target.value),
      netWeight: e.target.value !== "" ? parseFloat(e.target.value) : "",
      cmdityCost:
        alltradeDetail != null && alltradeDetail?.lineItems.length > 0
          ? alltradeDetail?.lineItems[0].weightUnit == "RATE_PER_UNIT"
            ? Number(arrivalIInfo?.destRate) *
              Number(alltradeDetail?.lineItems[0].qty)
            : parseFloat(e.target.value) * Number(arrivalIInfo?.destRate)
          : "",
      // invAmt: parseFloat(
      //   (parseFloat(e.target.value) * Number(alltradeDetail?.destRate)).toFixed(
      //     2
      //   )
      // ),
    });
  };
  const onChangeAddWastage = (e: ChangeEvent<HTMLInputElement>) => {
    let netWeight = Number(alltradeDetail?.destWeight) - Number(e.target.value);
    setArrivalIInfo({
      ...arrivalIInfo,
      netWeight: netWeight,
      cmdityCost:
        alltradeDetail != null && alltradeDetail?.lineItems.length > 0
          ? alltradeDetail?.lineItems[0].weightUnit == "RATE_PER_UNIT"
            ? Number(arrivalIInfo?.destRate) *
              Number(alltradeDetail?.lineItems[0].qty)
            : Number(alltradeDetail?.destRate) *
              (Number(alltradeDetail?.destWeight) - Number(e.target.value))
          : "",
      // invAmt: parseFloat(
      //   (netWeight * Number(alltradeDetail?.destRate)).toFixed(2)
      // ),
      destWastage: e.target.value !== "" ? parseFloat(e.target.value) : "",
    });
  };
  // const onChangeinvAmt = (e: ChangeEvent<HTMLInputElement>) => {
  //   setArrivalIInfo({
  //     ...arrivalIInfo,
  //     destRate: parseFloat(
  //       (
  //         parseFloat(e.target.value) / Number(alltradeDetail?.netWeight)
  //       ).toFixed(2)
  //     ),
  //     invAmt: e.target.value !== "" ? parseFloat(e.target.value) : "",
  //   });
  // };
  const onChangeDestweight = (e: ChangeEvent<HTMLInputElement>) => {
    let netWeight =
      Number(e.target.value) - Number(alltradeDetail?.destWastage);
    setArrivalIInfo({
      ...arrivalIInfo,
      destWeight: e.target.value !== "" ? parseFloat(e.target.value) : "",
      netWeight: netWeight,
      // invAmt: Number(netWeight) * Number(alltradeDetail?.destRate),
      cmdityCost:
        alltradeDetail != null && alltradeDetail?.lineItems.length > 0
          ? alltradeDetail?.lineItems[0].weightUnit == "RATE_PER_UNIT"
            ? Number(arrivalIInfo?.destRate) *
              Number(alltradeDetail?.lineItems[0].qty)
            : netWeight * Number(arrivalIInfo?.destRate)
          : "",
    });
  };
  const onChangeCostAmt = (e: ChangeEvent<HTMLInputElement>) => {
    console.log(parseFloat(e.target.value));
    setArrivalIInfo({
      ...arrivalIInfo,
      cmdityCost: e.target.value !== "" ? parseFloat(e.target.value) : "",
      destRate:
        alltradeDetail != null && alltradeDetail?.lineItems.length > 0
          ? alltradeDetail?.lineItems[0].weightUnit == "RATE_PER_UNIT"
            ? parseFloat(
                (
                  parseFloat(e.target.value) /
                  Number(alltradeDetail?.lineItems[0].qty)
                ).toFixed(2)
              )
            : parseFloat(
                (
                  parseFloat(e.target.value) / Number(alltradeDetail?.netWeight)
                ).toFixed(2)
              )
          : "",
    });
  };
  return (
    <div>
      <div
        className="pb-3 overflow-y-auto"
        // style={{ height: "calc(100vh - 5rem)" }}
      >
        <div className="bg-white px-3 py-4 mb-3">
          <div className="flex gap-4">
            <div className="grow-[3]">
              <span className="text-sm">
                Arrival Date<span className="color-red">*</span>
              </span>
              <SelectDate
                onChange={handleDateSelection}
                selectedDate={alltradeDetail?.arlDate!}
              />
            </div>
            <div className="grow-[3]">
              <span className="text-sm">
                Arrival Time<span className="color-red">*</span>
              </span>
              <div
                className="border mt-2 flex justify-between br-10 p-2 color-blue cursor-pointer"
                onClick={() => {
                  setTimePickerModal(true);
                }}
              >
                {/* {alltradeDetail?.arlTime} */}
                {alltradeDetail != null && alltradeDetail?.arlTime != ""
                  ? alltradeDetail?.arlTime != null
                    ? getTimeformat12(alltradeDetail?.arlTime)
                    : alltradeDetail?.arlTime
                  : alltradeDetail?.arlTime}
                <FaClock />
              </div>
              {/* <SelectDate /> */}

              {/* <div>
              <TimePicker
                value={value}
                onChange={(value) => timeChange(value!)}
                id="time-picker"
                format="hh:mm:ss a"
                locale="hu-HU"
              />
            </div> */}
              {/* <SelectDate /> */}
            </div>
          </div>
          <div className="pt-3">
            <span className="text-sm">
              Freight Balance Paid<span className="color-red">*</span>
            </span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="number"
                required={true}
                value={arrivalIInfo?.freightPaid}
                onChange={(e) => {
                  setArrivalIInfo({
                    ...arrivalIInfo,
                    freightPaid:
                      e.target.value != "" ? parseFloat(e.target.value) : "",
                    netWeight: alltradeDetail?.netWeight || "",
                    // parseFloat(e.target.value),
                  });
                }}
                onFocus={(e) => {
                  e.target.value === "0" &&
                    setArrivalIInfo({
                      ...arrivalIInfo,
                      freightPaid: "",
                    });
                }}
              />
              <div className="flex">
                <span className="text-sm color-sub-text">
                  Total Freight:{" "}
                  {getCurrencyNumberWithSymbol(alltradeDetail?.freightAmt) +
                    " "}
                </span>
                <span className="text-sm color-sub-text">
                  | Advance:
                  {getCurrencyNumberWithSymbol(alltradeDetail?.freightAdv) || 0}
                </span>
              </div>
              {Number(alltradeDetail?.freightPaid) > 0 &&
                Number(alltradeDetail?.freightAmt) >
                  Number(alltradeDetail?.freightPaid) && (
                  <div className="text-sm color-sub-text">
                    Pending Freight :{" "}
                    {getCurrencyNumberWithSymbol(
                      Number(alltradeDetail?.freightAmt) -
                        Number(alltradeDetail?.freightAdv) -
                        Number(alltradeDetail?.freightPaid)
                    )}
                  </div>
                )}
            </div>
          </div>
          <div className="flex pt-3 gap-4">
            <div className="grow-[3]">
              <span className="text-sm">
                Weight at Source<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={
                    (alltradeDetail?.lineItems.length != 0 &&
                      alltradeDetail?.lineItems[0].bayerWeight) ||
                    0
                  }
                  disabled={true}
                />
                <>
                  <div
                    className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                    style={{
                      top: ".8rem",
                      right: "1rem",
                      cursor: "pointer",
                      width: "6rem",
                    }}
                  >
                    <span className="">{"KGS"}</span>
                  </div>
                </>
              </div>
            </div>

            <div className="grow-[3]">
              <span className="text-sm">
                Weight at Destination<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={arrivalIInfo?.destWeight}
                  onChange={onChangeDestweight}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        destWeight: "",
                      });
                  }}
                />
                <>
                  <div
                    className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                    style={{
                      top: ".8rem",
                      right: "1rem",
                      cursor: "pointer",
                      width: "6rem",
                    }}
                  >
                    <span className="">{"KGS"}</span>
                  </div>
                </>
              </div>
              {alltradeDetail?.destWeight! > 0 && (
                <span className="text-sm color-sub-text">
                  Moisture Loss:
                  {getCurrencyNumberWithOutSymbol(
                    alltradeDetail?.moistureLoss
                  ) + "KGS"}
                </span>
              )}
            </div>
          </div>
          <div className="flex pt-3 gap-4">
            <div className="grow-[3]">
              <span className="text-sm">Additional Wastage</span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={arrivalIInfo?.destWastage}
                  onChange={onChangeAddWastage}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        destWastage: "",
                      });
                  }}
                  style={{ color: "var(--ono-red)" }}
                />
                <>
                  <div
                    className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                    style={{
                      top: ".8rem",
                      right: "1rem",
                      cursor: "pointer",
                      width: "6rem",
                    }}
                  >
                    <span className="">{"KGS"}</span>
                  </div>
                </>
              </div>
            </div>
            <div className="grow-[3]">
              <span className="text-sm">
                Net Weight<span className="color-red">*</span>
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={alltradeDetail?.netWeight}
                  onChange={
                    onChangeNetweight
                    // setArrivalIInfo({
                    //   ...arrivalIInfo,
                    //   netWeight: parseFloat(e.target.value),
                    // });
                  }
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        netWeight: "",
                      });
                  }}
                />
                <>
                  <div
                    className="absolute cursor-pointer border-l pl-2 flex justify-center text-sm"
                    style={{
                      top: ".8rem",
                      right: "1rem",
                      cursor: "pointer",
                      width: "6rem",
                    }}
                  >
                    <span className="">{"KGS"}</span>
                  </div>
                </>
              </div>
            </div>
          </div>
          {alltradeDetail?.tradeMode == "ORDER" && (
            <div className="flex pt-3 gap-4">
              <div className="grow-[3]">
                <span className="text-sm">
                  Original Rate<span className="color-red">*</span>
                </span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.rate}
                    disabled={true}
                  />
                  <></>
                </div>
              </div>
              <div className="grow-[3]">
                <span className="text-sm">
                  Final Rate<span className="color-red">*</span>
                </span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={arrivalIInfo?.destRate}
                    onChange={(e) => {
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        destRate:
                          e.target.value != ""
                            ? parseFloat(e.target.value)
                            : "",
                        netWeight: alltradeDetail?.netWeight,
                        cmdityCost:
                          alltradeDetail != null &&
                          alltradeDetail?.lineItems.length > 0
                            ? alltradeDetail?.lineItems[0].weightUnit ==
                              "RATE_PER_UNIT"
                              ? parseFloat(e.target.value) *
                                Number(alltradeDetail?.lineItems[0].qty)
                              : parseFloat(e.target.value) *
                                Number(alltradeDetail?.netWeight)
                            : "",
                        // invAmt: parseFloat(
                        //   (
                        //     parseFloat(e.target.value) *
                        //     Number(alltradeDetail?.netWeight)
                        //   ).toFixed(2)
                        // ),
                      });
                    }}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          destRate: "",
                        });
                    }}
                  />
                  <></>
                </div>
                {alltradeDetail?.lineItems.length > 0 &&
                  alltradeDetail?.lineItems[0].weightUnit ==
                    "RATE_PER_UNIT" && (
                    <div className="pt-3 flex items-center text-sm color-sub-text">
                      <span className="text-sm">Total Quantity: </span>
                      <div className="">{alltradeDetail?.lineItems[0].qty}</div>
                    </div>
                  )}
                {/* {alltradeDetail?.destRate! > 0 && (
                  <span className="text-sm color-sub-text">
                    Final Invoice Amount:
                    {getCurrencyNumberWithSymbol(
                      Number(alltradeDetail?.destRate) *
                        Number(alltradeDetail?.netWeight)
                    )}
                  </span>
                )} */}
              </div>
            </div>
          )}
          {alltradeDetail?.tradeMode == "ORDER" && (
            <div className="flex pt-3 gap-4">
              <div className="grow-[3] ">
                <span className="text-sm color-sub-text">
                  Final Commodity Cost<span className="color-red">*</span>
                </span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.cmdityCost}
                    onChange={onChangeCostAmt}
                    onFocus={(e) => {
                      e.target.value === "0" &&
                        setArrivalIInfo({
                          ...arrivalIInfo,
                          cmdityCost: "",
                        });
                    }}
                  />
                </div>
              </div>
              <div className="grow-[3] ">
                <span className="text-sm color-sub-text">
                  Final Invoice Amount
                </span>
                <div className="mt-2 relative">
                  <Input
                    label=""
                    type="number"
                    required={true}
                    value={alltradeDetail?.invAmt}
                    disabled={true}
                    // onChange={onChangeinvAmt}
                    // onFocus={(e) => {
                    //   e.target.value === "0" &&
                    //     setArrivalIInfo({
                    //       ...arrivalIInfo,
                    //       invAmt: "",
                    //     });
                    // }}
                  />
                </div>
              </div>
            </div>
          )}
          <div className="flex">
            <div className="grow-[3] mt-3">
              <span className="text-sm color-sub-text">
                {}
                Truck Number
              </span>
              <div className="mt-2 relative">
                <Input
                  label=""
                  type="text"
                  required={true}
                  value={alltradeDetail?.destVehNum}
                  onChange={(e) => {
                    setArrivalIInfo({
                      ...arrivalIInfo,
                      destVehNum: e.target.value != "" ? e.target.value : "",
                      netWeight: alltradeDetail?.netWeight || 0,
                    });
                  }}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setArrivalIInfo({
                        ...arrivalIInfo,
                        destVehNum: "",
                      });
                  }}
                />
              </div>
            </div>
          </div>
          {/* <div className="pt-3">
            <span className="text-sm">Comments</span>
            <div className="mt-2 relative">
              <Input
                label=""
                type="text"
                required={true}
                value={alltradeDetail?.arlComments}
                onChange={(e) => {
                  setArrivalIInfo({
                    ...arrivalIInfo,
                    arlComments: e.target.value,
                  });
                }}
              />
              <></>
            </div>
          </div> */}
        </div>
      </div>
      <div className="flex items-center bottom-0 right-0 w-full bg-white px-4 py-4 border-top gap-2">
        <Button
          variant="primaryLight"
          className="w-full"
          text="CANCEL"
          onClick={() => {
            onArrivalModalClose(false);
          }}
        />
        <Button
          variant="contained"
          className="w-full"
          onClick={() => {
            confrirmArrival();
            // onArrivalModalClose(false);
          }}
        >
          SAVE
        </Button>
      </div>
      {timePickerModal === true && (
        <>
          <Modal
            open={timePickerModal}
            width="30vw"
            height="50vh"
            onClose={() => {
              setTimePickerModal(false);
            }}
            // modalWidth={deviceType === "MOBILE" ? "100vw" : width}
          >
            <>
              <ModalHeader title={"Select Time"} content={<></>} />
              <div className="py-4">
                <TimePicker
                  onChange={handleTimeSelection}
                  selectedTime={alltradeDetail?.arlTime!}
                  editTime={editStatus == "edit" ? true : false}
                  onModalClose={setTimePickerModal}
                />
              </div>
              {/* <div className="flex items-center px-4 py-4 border-top">
                <Button
                  variant="contained"
                  onClick={() => {
                    // handleTimeSelection;
                    setTimePickerModal(false);
                  }}
                  styles={{ width: "100%" }}
                >
                  OK
                </Button>
              </div> */}
            </>
          </Modal>
        </>
      )}
    </div>
  );
};
export default ArrivalConfirmation;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TRootState } from "../store";
import moment from "moment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Compressor from "compressorjs";
import { TProposals, TSupplyDemandReq } from "../types";
const useProposalEdit = ({
  id,
  modalClose,
  type,
}: {
  id?: number;
  modalClose?: any;
  type?: string;
}) => {
  const { handleGetCall, handlePutCall, handlePostCall } = useAxiosClient();
  const navigate = useNavigate();

  const { traderContactInfo, adminId, supplyDetailsById } = useSelector(
    (state: TRootState) => ({
      traderContactInfo: state.trades.traderContactInfo,
      adminId: state.auth.adminId,
      supplyDetailsById:
        type == "SUPPLY"
          ? state.trades.supplyDetailsById
          : state.trades.demandDetailsById,
    })
  );

  const [details, setProposalDetails] = useState<TProposals | null>(
    traderContactInfo
  );
  const [selectedDate, setSelectedDate] = useState(
    details?.expDate ? details?.expDate : ""
  );
  let traderId = details?.traderId || 0;
  const handleDateSelection = (_date: string) => {
    let date = moment(_date).format("YYYY-MM-DD");
    //  setCalenderOpen(false);
    setSelectedDate(date);
    setProposalDetails({
      ...details!,
      expDate: date,
    });
  };

  const submit = async () => {
    let imgs: Array<{
      id: number;
      status: number;
      addedBy: number;
      type: string;
      url: string;
    }> = [];
    let images = imgs;
    // if (details?.cropName == "") {
    //   toast.error("Please select Crop ");
    //   return;
    // }
    // if (details?.quality == "") {
    //   toast.error("Please select Quality ");
    //   return;
    // }
    // if (details?.unitType == "") {
    //   toast.error("Please select Unit Type ");
    //   return;
    // }
    // if (details?.qty == 0) {
    //   toast.error("Please enter Quantity");
    //   return;
    // }
    if (details?.rate == 0) {
      toast.error("Please enter Rate");
      return;
    }
    if (details?.expDate == "") {
      toast.error("Please select Expected Date");
      return;
    }
    // if (cropImages.length === 0 && supplyDetails?.imgs == null) {
    //   toast.error("Please upload atleast one image");
    //   return;
    // }

    // if (details != null) {
    //   if (details?.imgs && supplyDetails?.imgs.length > 0) {
    //     for (let file of supplyDetails?.imgs) {
    //       if (file.status == 0) {
    //         images.push({
    //           ...file,
    //           addedBy: adminId,
    //           id: file.id,
    //         });
    //       }
    //     }
    //   }
    // }
    // }
    // if (cropImages.length > 0) {
    //   for (let file of cropImages) {
    //     images.push({
    //       id: 0,
    //       status: 1,
    //       addedBy: adminId || 0,
    //       type: "supply",
    //       url: file.url,
    //     });
    //   }
    // }

    try {
      let postBody = {
        expDate: details?.expDate,
        id: details?.id,
        offerValidity: details?.offerValidity,
        rate: details?.rate,
        traderId: details?.traderId,
        type: type,
        validity: details?.validity,
      };

      await handlePutCall<any>({
        URL: `admin/demand-supply/proposals/adminId/${adminId}`,
        apiParams: postBody,
      })
        .then(({ data, status }) => {
          toast.success(status.message);
          if (type == "SUPPLY") {
            navigate(`/supply-details/${id}`);
          }
          if (type == "DEMAND") {
            navigate(`/demand-details/${id}`);
          }
          modalClose(false);
        })
        .catch((err: any) => {
          toast.error(err.response.data.status.message);
        });
    } catch (ex) {}
  };
  return {
    details,
    handleDateSelection,
    setProposalDetails,
    submit,
    supplyDetailsById,
    selectedDate,
  };
};
export default useProposalEdit;

import { ChangeEvent, FC, useEffect, useState } from "react";
import { IoMdCloseCircleOutline, IoMdSearch } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import PartnerInfo from "../../components/partner-info";
import SelectCrop from "../../components/select-crop";
import { Button, FileUploader, Input, RadioButton } from "../../components/ui";
import SelectDate from "../../components/ui/select-date";
import PartyDropDown from "../../components/ui/select-party";
import Select2, { OnoSelect } from "../../components/ui/select2";
import { updateCropData } from "../../reducers/tradeSlice";
import "./style.module.scss";
import { FaXmark } from "react-icons/fa6";
import DocumentPreview from "../../components/commons/document-preview";
import StyledRadioButton from "../../components/ui/styledRadioButton";
import useSupplyDetailsEdit from "../../hooks/useSupplyPostEdit";
import useSupplyDetailsById from "./useSupplyDetails";
import { getCurrencyNumberWithSymbol } from "../../helpers/get-currency-number";
import moment from "moment";
const calculate = ({
  qty,
  rate,
  qtyUnit,
}: {
  qty: number;
  rate: number | string;
  qtyUnit: string | number;
}) => {
  let total = 0;
  if (qtyUnit == "MT/TON") {
    total = Number(qty) * 1000 * Number(rate);
  } else if (qtyUnit == "QUINTAL") {
    total = Number(qty) * 100 * Number(rate);
  } else {
    total = Number(qty) * Number(rate);
  }
  return parseFloat(total.toFixed(2));
};
let calculateTimer: any = null;
const EditSupplyPost: FC<{
  onModalClose: any;
  type?: string;
  fromSupplyTab?: boolean;
  postID?: any;
}> = ({ onModalClose, type, fromSupplyTab, postID }) => {
  const dispatch = useDispatch();
  const params = useParams();
  // const { getSupplyDetail } = useSupplyDetailsById({
  //   id: fromSupplyTab ? postID || "0" : parseInt(params.postId || "0"),
  // });
  // useEffect(() => {
  //   getSupplyDetail();
  // }, [fromSupplyTab]);
  const {
    supplyDetails,
    handleDateSelection,
    setSupplyDetails,
    submit,
    // onRemoveReceiptImage,
    onUploadReceiptImage,
    kycDocs,
    selectedDate,
    cropImages,
    setCropImages,
    onRemoveReceiptImage,
  } = useSupplyDetailsEdit({
    id: fromSupplyTab ? postID || "0" : parseInt(params.postId || "0"),
    modalClose: onModalClose,
    fromSupplyTab: fromSupplyTab,
  });
  const [totalVal, setTotal] = useState(supplyDetails?.expTotal);
  const initCalculate = () => {
    if (calculateTimer) {
      clearTimeout(calculateTimer);
    }
    calculateTimer = setTimeout(() => {
      let total = calculate({
        qty: typeof supplyDetails?.qty === "number" ? supplyDetails?.qty : 0,
        rate: supplyDetails?.expRate ? supplyDetails?.expRate : 0,
        qtyUnit: supplyDetails?.unitType || "",
      });
      setTotal(total);
      setSupplyDetails({
        ...supplyDetails!,
        expTotal: total,
      });
    }, 300);
  };
  useEffect(() => {
    initCalculate();
  }, [supplyDetails?.qty, supplyDetails?.expRate, supplyDetails?.unitType]);
  console.log(supplyDetails, "supplyDetails");
  return (
    <>
      <div
        className="pb-3 overflow-y-auto"
        style={{ height: "calc(100vh - 22rem)" }}
      >
        <div className="pb-3 w-full flex gap-4">
          <div className="grow-[3]">
            <div
              className={`relative flex items-center bg-white shrink-0 px-2 py-2 border br-10`}
              style={{ borderRightWidth: "1px", height: "70px" }}
            >
              <div className={`flex items-center cursor-pointer w-full`}>
                <PartnerInfo
                  profilePic={supplyDetails?.profilePic || ""}
                  name={supplyDetails?.traderName || ""}
                  shortName={""}
                  partnerType={"Trader"}
                  partnerId={supplyDetails?.traderId}
                  partnerMobile={supplyDetails?.mobile || ""}
                  partyName={supplyDetails?.addressLine}
                />
              </div>
            </div>
          </div>
          <div className="grow-[3]">
            <div className="grow overflow-auto scrollbar">
              <SelectCrop status="true" />
            </div>
          </div>
        </div>
        <div className="pt-3 w-full flex gap-4">
          <div className="grow-[3]">
            <label className="text-sm">
              Quality <span className="color-red">{"*"}</span>
            </label>
            <StyledRadioButton
              displayType="horizontal"
              data={[
                { label: "Best", value: "BEST" },
                { label: "Average", value: "AVERAGE" },
                { label: "Low", value: "LOW" },
              ]}
              value={supplyDetails?.quality || ""}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                setSupplyDetails({
                  ...supplyDetails!,
                  quality: value || "",
                });
              }}
              styles={{ display: "flex", gap: "10px" }}
            />
          </div>
          <div className="grow-[3]">
            <label className="text-sm">
              Unit Type <span className="color-red">{"*"}</span>
            </label>
            <StyledRadioButton
              displayType="horizontal"
              data={[
                { label: "KGS", value: "KGS" },
                { label: "Quintal", value: "QUINTAL" },
                { label: "MT/TON", value: "MT/TON" },
              ]}
              value={supplyDetails?.unitType || ""}
              pointer={{ label: "label", value: "value" }}
              onChange={(value) => {
                setSupplyDetails({
                  ...supplyDetails!,
                  unitType: value || "",
                });
              }}
              styles={{ display: "flex" }}
            />
          </div>
        </div>

        <div className="pt-3 w-full flex gap-4">
          <table>
            <tr>
              <th className="text-sm font-medium">Quantity*</th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={supplyDetails?.qty}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setSupplyDetails({
                        ...supplyDetails!,
                        qty: "",
                      });
                  }}
                  onChange={(e) => {
                    setSupplyDetails({
                      ...supplyDetails!,
                      qty:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                />
              </td>
            </tr>
            <tr>
              <th className="text-sm font-medium">Expected Rate* (Per KG)</th>
              <td>
                <Input
                  label=""
                  type="number"
                  required={true}
                  value={supplyDetails?.expRate}
                  onFocus={(e) => {
                    e.target.value === "0" &&
                      setSupplyDetails({
                        ...supplyDetails!,
                        expRate: "",
                      });
                  }}
                  onChange={(e) => {
                    setSupplyDetails({
                      ...supplyDetails!,
                      expRate:
                        e.target.value != "" ? parseFloat(e.target.value) : "",
                    });
                  }}
                  fromSupplyTab={true}
                />
              </td>
            </tr>
            <tr>
              <th
                className="text-sm font-medium"
                style={{ borderRight: "0px" }}
              ></th>
              <td
                className="text-sm font-medium "
                style={{ borderLeft: "0px" }}
              >
                Expected Total :{" "}
                <span className="color-primary">
                  {getCurrencyNumberWithSymbol(totalVal) || "0"}
                </span>
              </td>
            </tr>
          </table>
        </div>
        <div className="pt-3">
          {type == "Supply" ? (
            <div className="grow-[3]" style={{ width: "45%" }}>
              <span className="text-sm">
                Availability Date <span className="color-red">*</span>
              </span>
              <SelectDate
                onChange={handleDateSelection}
                selectedDate={selectedDate || ""}
                maxDate={moment().add(2, "weeks").format("YYYY-MM-DD")}
              />
            </div>
          ) : (
            <div className="flex gap-4">
              <div className="grow-[3]">
                <span className="text-sm">
                  Expected Date <span className="color-red">*</span>
                </span>
                <SelectDate
                  onChange={handleDateSelection}
                  selectedDate={selectedDate || ""}
                  maxDate={moment().add(2, "weeks").format("YYYY-MM-DD")}
                />
              </div>
              <div className="grow-[3]">
                <span className="text-sm">
                  Offer Validity <span className="color-red">*</span>
                </span>
                <Select2
                  value={supplyDetails?.validity}
                  options={[
                    { label: "Today Only", value: "Today only" },
                    {
                      label: "Next 1 Days",
                      value: "Next 1 days",
                    },
                    {
                      label: "Next 2 Days",
                      value: "Next 2 days",
                    },
                    {
                      label: "Next 3 Days",
                      value: "Next 3 days",
                    },
                  ]}
                  onChange={(e) => {
                    setSupplyDetails({
                      ...supplyDetails!,
                      validity: e.value,
                    });
                  }}
                />
                {/* <SelectDate
                  onChange={handleDateSelection}
                  selectedDate={selectedDate || ""}
                /> */}
              </div>
            </div>
          )}
        </div>
        <div className="pt-3">
          <span className="text-sm">
            Upload Photo <span className="color-red">*</span>
          </span>
          <div className="flex flex-wrap gap-2 overflow-auto pb-4">
            <div className="shrink-0 overflow-hidden p-2">
              <FileUploader
                label=""
                onChange={(e) => {
                  onUploadReceiptImage(
                    "supply",
                    e.target.files ? e.target.files[0] : null
                  );
                  // setOnEdit(true);
                }}
                fromCropUpload={true}
              />
            </div>
            {cropImages &&
              cropImages?.length > 0 &&
              cropImages?.map((image, i) => (
                <div
                  className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                  style={{
                    width: "20%",
                    height: "8rem",
                  }}
                >
                  <a href={image.url} target="_blank">
                    <DocumentPreview url={image.url} docType={image.docType} />
                  </a>

                  <span
                    className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                    style={{
                      background: "#ff0000de",
                    }}
                    onClick={() => {
                      onRemoveReceiptImage("supply", i);
                    }}
                  >
                    <FaXmark style={{ color: "white" }} />
                  </span>
                </div>
              ))}
            {supplyDetails?.imgs &&
              supplyDetails?.imgs.length &&
              supplyDetails.imgs
                .filter((img) => img.status == 1)
                .map((image, i) => (
                  <div
                    className="shrink-0 overflow-hidden border-2 p-2 rounded relative"
                    style={{
                      width: "20%",
                      height: "8rem",
                    }}
                  >
                    <a href={image.url} target="_blank">
                      <DocumentPreview url={image.url} docType={"image"} />
                    </a>

                    <span
                      className="absolute right-1 top-1 p-1 rounded-full cursor-pointer"
                      style={{
                        background: "#ff0000de",
                      }}
                      onClick={() => {
                        onRemoveReceiptImage("supply", image.id, true);
                      }}
                    >
                      <FaXmark style={{ color: "white" }} />
                    </span>
                  </div>
                ))}
          </div>
          <span className="text-sm color-sub-text">
            Note: Please submit photos of harvested produce only, not crops on
            the field.
          </span>
        </div>
      </div>
      <div>
        <div className="flex items-center bottom-0 right-0 w-full bg-white py-4 justify-between border-top gap-2">
          <Button
            variant="primaryLight"
            className=""
            text="CANCEL"
            onClick={() => {
              onModalClose(false);
            }}
          />
          <Button
            variant="contained"
            className=""
            onClick={() => {
              submit();
            }}
          >
            SAVE
          </Button>
        </div>
      </div>
    </>
  );
};
export default EditSupplyPost;

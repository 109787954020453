import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useAxiosClient from "./useAxiosClient";
import { TSupplyDemandReq, TTrader } from "../types/index";
import { TRootState } from "../store";

const useAllDemands = () => {
  const { handleGetCall } = useAxiosClient();
  const { adminId, role, activeTradeTab, allDemandsFromApi } = useSelector(
    (state: TRootState) => ({
      adminId: state.auth.adminId,
      role: state.auth.userInfo?.roleType,
      activeTradeTab: state.trades.activeTradeTab,
      allDemandsFromApi: state.trades.allDemands,
    })
  );
  const [allDemands, setAllDemands] = useState<TSupplyDemandReq[]>([]);
  const [activeTab, setActiveTab] = useState("all");
  const [dataLength, setDataLength] = useState<{
    typeTab: string;
    penLength?: number;
    allLength?: number;
    assignLength?: number;
    expLength?: number;
  }>({
    typeTab: "",
    penLength: 0,
    allLength: 0,
    assignLength: 0,
    expLength: 0,
  });
  useEffect(() => {
    handleGetCall<TSupplyDemandReq[]>(
      `admin/demand-supply/adminId/${adminId}`
    ).then(({ data }) => {
      if (data) {
        if (activeTab == "pending") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "PENDING"
          );
          setAllDemands(filterArray);
        } else if (activeTab == "assigned") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "ASSIGNED"
          );
          setAllDemands(filterArray);
        } else if (activeTab == "expired") {
          const filterArray = data.filter(
            (item) => item?.status?.toUpperCase() == "EXPIRED"
          );
          setAllDemands(filterArray);
        } else {
          setAllDemands(data);
        }
        const calculateLengths = () => {
          const allLength = data.length;
          const penLength = data.filter(
            (item) => item?.status?.toUpperCase() === "PENDING"
          ).length;
          const assignLength = data.filter(
            (item) => item?.status?.toUpperCase() === "ASSIGNED"
          ).length;
          const expLength = data.filter(
            (item) => item?.status?.toUpperCase() === "EXPIRED"
          ).length;

          setDataLength({
            typeTab: activeTab,
            penLength,
            allLength,
            assignLength,
            expLength,
          });
        };

        calculateLengths();
      } else {
        setAllDemands([]);
      }
    });
  }, [activeTradeTab, activeTab, allDemandsFromApi]);

  return { allDemands, role, activeTab, setActiveTab, dataLength };
};
export default useAllDemands;

import EmptyProfile from "../../../assets/images/profile.jpg";
import mandii from "../../../assets/images/mandii.png";
import timer from "../../../assets/images/timer-img.png";
import phone from "../../../assets/images/phone-logo.png";
import document from "../../../assets/images/document.png";
import vehicle from "../../../assets/images/vehicle.png";
import location from "../../../assets/images/location.png";
import tickmark from "../../../assets/images/tickmark.png";
import hold from "../../../assets/images/hold.svg";
import reject from "../../../assets/images/reject.svg";
import blocked from "../../../assets/images/blocked.svg";
import { TTraderId } from "../../../types";
import DocumentPreview from "../../../components/commons/document-preview";
import { useState } from "react";
import AddTrader from "../AddTrader";
import Modal from "../../../components/ui/modal";
import { Button, CircularProgress } from "@mui/material";
import market from "../../../assets/images/market.png";
import bank from "../../../assets/images/bank.svg";
import { getCurrencyNumberWithSymbol, getMaskedMobileNumber } from "../../../helpers/get-currency-number";
import { useSelector } from "react-redux";
import { TRootState } from "../../../store";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const TradeDetails: React.FC<{
  trader: TTraderId | null | undefined;
  onSubmit: () => void;
  loading: boolean;
  activeTab: string;
  progressLimit: number;
}> = ({ trader, onSubmit, loading, activeTab, progressLimit }) => {
  const { role } = useSelector((state: TRootState) => ({
    role: state.auth.userInfo?.roleType,
  }));
  let [traderSummary, setTraderSummary] = useState({
    TotalReceivable: 0,
    PlatformFees: 0,
    ReceivedTillDate: 0,
    BalanceReceivable: 0,
  });
  const calculateTotal = () => {
    let total = 0;
    if (trader?.trades == null) {
      return total;
    }
    trader?.trades.forEach((trade) => {
      if (trader?.tradeType === "SOURCE") {
        total += trade.totalReceivable;
      } else {
        total += trade.totalPayable;
      }
    });
    return getCurrencyNumberWithSymbol(total)
  };

  const calculatePlatform = () => {
    let total = 0;
    if (trader?.trades == null) {
      return total;
    }
    trader?.trades.forEach((trade) => {
      if (trader?.tradeType === "SOURCE") {
        total += trade.srcPf;
      } else {
        total += trade.destPf;
      }
    });
    return getCurrencyNumberWithSymbol(total)
  };

  const calculateTillDate = () => {
    let total = 0;
    if (trader?.trades == null) {
      return total;
    }
    trader?.trades.forEach((trade) => {
      if (trader?.tradeType === "SOURCE") {
        total += trade.outwardPmt;
      } else {
        total += trade.inwardPmt;
      }
    });
    return getCurrencyNumberWithSymbol(total)
  };

  const calculateBalance = () => {
    let total = 0;
    if (trader?.trades == null) {
      return total;
    }
    trader?.trades.forEach((trade) => {
      if (trader?.tradeType === "SOURCE") {
        total += trade.totalReceivable - trade.inwardPmt;
      } else {
        total += trade.totalPayable - trade.outwardPmt;
      }
    });
    return getCurrencyNumberWithSymbol(total)
  };
  const [addTrader, setAddTrader] = useState(false); 

  const truncateText = (text: string, wordLimit: number): string => {
    const wordsArray = text.split(" ");
    return wordsArray.length > wordLimit
      ? wordsArray.slice(0, wordLimit).join(" ") + "..."
      : text;
  };

  const documents = [
    "AADHAR",
    "PAN",
    "ITR",
    "TRADE_LICENSE",
    "BANK_STATEMENT",
    "INVOICE",
    "PDC",
  ];

  let missingDocs: string[] = [];
  console.log(trader?.kycDocs);
  documents.forEach((doc) => {
    let index = trader?.kycDocs.findIndex((kyc) => kyc.type == doc)!;
    console.log(index);
    if (index < 0) missingDocs.push(doc);
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const visibleDocs = isExpanded ? missingDocs : missingDocs.slice(0, 3);

  const progressValue = trader?.traderProgress
    ? parseFloat(trader.traderProgress.toString().replace("%", ""))
    : 0;

  const [internalCommentsExpand, setInternalCommentsExpand] = useState(false);
  const [externalCommentsExpand, setExternalCommentsExpand] = useState(false);

  const toggleComments = () => {
    setIsExpanded((prev) => !prev);
  };

  const internalCommentsToggle = () =>{
    setInternalCommentsExpand((prev) => !prev);
  }
  const externalCommentsToggle = () =>{
    setExternalCommentsExpand((prev) => !prev);
  }

  const truncatedText = (text: any, maxLength: any) => {
    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
  };

  return (
    <div className="bg-gray">
      <div
        className="flex mr-4"
      >
        <div className="w-[40%] overflow-y-scroll scrollbar-hide"
        style={{ 
          height: "calc(100vh - 8rem)",
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
        >
          <div>
            <div className="h-auto border-gray m-[20px] br-10">
              <div className="h-auto border-b bg-light-primary rounded-t-md flex py-2 items-center justify-between">
                <div className="flex">
                  <span>
                    <img
                      src={EmptyProfile}
                      alt="EmptyProfile"
                      className="h-8 ml-[20px]"
                    />
                  </span>
                  <span className="text-base flex items-center justify-center">
                    Trader Details
                  </span>
                </div>
                {role == "ADMIN" && (
                  <div className="px-5">
                    <Button
                      onClick={() => {
                        setAddTrader(true);
                      }}
                    >
                      <span className="text-green-600 text-base">EDIT</span>
                    </Button>
                  </div>
                )}
              </div>

              <div>
                <Modal
                  open={addTrader}
                  onClose={() => {
                    setAddTrader(false);
                  }}
                  width="full"
                >
                  <AddTrader
                    mode="EDIT"
                    onRefresh={() => {
                      onSubmit();
                    }}
                    onClose={() => {
                      setAddTrader(false);
                    }}
                  />
                </Modal>
              </div>

              <div className="p-2 bg-white br-10">
                <div className="flex justify-between py-2">
                  <div className="flex">
                    <div className="m-1">
                      {(trader?.profilePic === "" ||
                        trader?.profilePic === null) && (
                          <img
                            src={EmptyProfile}
                            alt=""
                            className="w-8 h-8 mr-1"
                          />
                        )}
                      {trader?.profilePic !== "" &&
                        trader?.profilePic !== null && (
                          <img
                            src={trader?.profilePic}
                            alt=""
                            className="h-10 w-10 br-100 mt-1"
                          />
                        )}
                    </div>
                    <div>
                      <span className="font-semibold text-sm">
                        {trader?.tradeName}
                      </span>
                      <br></br>
                      <span className="text-sm">
                        Trader ID -
                        <span className="color-blue">{trader?.traderId}</span> |
                        <span className="ml-1 text-sm">
                          {" "}
                          {getMaskedMobileNumber(trader?.mobile || "")}
                        </span>
                      </span>
                      <br></br>
                      <span className="color-sub-text text-sm">
                        {trader?.name}
                      </span>
                    </div>
                  </div>

                  <div className="">
                    <div style={{ height: "60px", width: "60px" }}>
                      <CircularProgressbar
                        value={progressValue}
                        text={`${progressValue}%`}
                        strokeWidth={13}
                        styles={buildStyles({
                          pathColor: "#16A02C",
                          textColor: "#16A02C",
                          trailColor: "#D7F3DD",
                          textSize: "25px",
                        })}
                      />
                    </div>
                  </div>

                  <div className="px-2">
                    <span className="text-base font-semibold">
                      {visibleDocs?.length > 0 ? " Pending Documents " : ""}

                    </span>
                    {visibleDocs?.length !== undefined &&
                      visibleDocs?.length > 0 ? (
                      <ul className="list-disc px-4">
                        {visibleDocs.map((doc, index) => (
                          <li key={index} className="text-sm mb-1">
                            {doc}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <div className="px-2">
                        <span className="text-sm font-semibold">
                          All Required documents are available!
                        </span>
                      </div>
                    )}

                    {missingDocs.length > 4 && (
                      <button
                        onClick={() => setIsExpanded(!isExpanded)}
                        className="color-blue text-sm"
                      >
                        {isExpanded ? "Show less" : "Show more"}
                      </button>
                    )}
                  </div>
                </div>
                <div className="border-b"></div>

                <div className="flex p-2 bg-white border-b">
                  <div className="grow-[2] basis-0 px-2">
                    <span className="mt-2 color-sub-text">ONO RISK SCORE</span>{" "}
                    <br />
                    <span className="text-sm font-semibold">
                      {trader?.ors ? trader?.ors : "0"} / 10
                    </span>
                  </div>

                  <div className="border mr-4 w-[0px] h-[50px]  border-gray-300"></div>

                  <div className="grow-[2] basis-0 px-4 flex">
                    <div>
                      <span className="color-sub-text">Trader Type</span>
                      <br />
                      <span className="font-semibold text-sm">
                        {trader?.tradeType}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex p-2 border-b bg-white">
                  <div className="grow-[2] basis-0 px-2">
                    <span className="color-sub-text">Trade Limit (₹)</span>
                    <br />
                    <span className="font-semibold text-base">
                      {trader?.tradeLimit}
                    </span>
                  </div>

                  <div className="border mr-4 w-[0px] h-[50px]  border-gray-300"></div>

                  <div className="grow-[2] basis-0 px-2">
                    <span className="color-sub-text">Avl Limit (₹)</span>
                    <br />
                    <span className="font-semibold text-base">--</span>
                  </div>

                  <div className="border mr-4 w-[0px] h-[50px]  border-gray-300"></div>

                  <div className="grow-[2] basis-0 px-2">
                    <span className="color-sub-text">Renewal Date</span>
                    <br />
                    <span className="font-semibold text-base">
                      {trader?.renewalDate}
                    </span>
                  </div>
                </div>

                <div className="p-2 flex bg-white br-10">
                  <div className="grow-[4] basis-0 px-2">
                    <span className="color-sub-text">Comments(Internal)</span>
                    <br />
                    {trader?.comments ? (
                      <>
                        {internalCommentsExpand ? trader.comments : truncatedText(trader.comments, 30)}
                        <button
                          onClick={internalCommentsToggle}
                          className="ml-1 color-blue"
                        >
                          {internalCommentsExpand ? "Show Less" : "Show More"}
                        </button>
                      </>
                    ) : (
                      "--"
                    )}
                  </div>

                  <div className="border mr-4 w-[0px] h-[50px]  border-gray-300"></div>
                  <div className="grow-[2.5] basis-0 px-2">
                    <span className="color-sub-text">Status</span>
                    <br />
                    {trader?.status === "ACTIVE" && (
                      <div className="flex cursor-pointer color-primary font-semibold">
                        <div>
                          {" "}
                          <img src={tickmark} className="w-5 h-5 mt-1" />{" "}
                        </div>
                        <div className="ml-1">{trader?.status}</div>
                      </div>
                    )}

                    {trader?.status === "UNDER REVIEW" && (
                      <div className="flex cursor-pointer color-orange font-semibold">
                        <div>
                          {" "}
                          <img src={timer} className="w-5 h-5 mt-1" />{" "}
                        </div>
                        <div className="ml-1">{trader?.status}</div>
                      </div>
                    )}

                    {trader?.status === "HOLD" && (
                      <div className="flex cursor-pointer flex color-orange font-semibold">
                        <div>
                          {" "}
                          <img src={hold} className="w-5 h-5 mt-1" />{" "}
                        </div>
                        <div className="ml-1">{trader?.status}</div>
                      </div>
                    )}

                    {trader?.status === "REJECTED" && (
                      <div className="flex cursor-pointer color-red font-semibold">
                        <div>
                          {" "}
                          <img src={reject} className="w-5 h-5 mt-1" />{" "}
                        </div>
                        <div className="ml-1">{trader?.status}</div>
                      </div>
                    )}

                    {trader?.status === "BLOCKED" && (
                      <div className="flex cursor-pointer color-red font-semibold">
                        <div>
                          {" "}
                          <img src={blocked} className="w-5 h-5 mt-1" />{" "}
                        </div>
                        <div className="ml-1">{trader?.status}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex p-2 border-b bg-white"></div>
                <div className="bg-white br-10 px-4 pb-4">
                  <span className="color-sub-text block mb-1">
                    Comments (for the customer)
                  </span>
                   {trader?.externelComments ? (
                      <>
                        {externalCommentsExpand ? trader.externelComments : truncatedText(trader.externelComments, 30)}
                        <button
                          onClick={externalCommentsToggle}
                          className="ml-1 color-blue"
                        >
                          {externalCommentsExpand ? "Show Less" : "Show More"}
                        </button>
                      </>
                    ) : (
                      "--"
                    )}
                </div>
              </div>
            </div>

            <div className=" h-auto border-gray ml-[20px] mr-[20px] br-10 pb-4 bg-white">
              <div className="h-[50px] border-b bg-light-primary rounded-t-md flex items-center">
                <img src={mandii} alt="mandii" className="h-5 ml-[20px] " />{" "}
                <span className="ml-[10px] text-base">Business Details</span>
              </div>
              <div className="flex mt-2">
                <div className="grow-[3.8] basis-0 px-2 flex">
                  <div className="m-1">
                    <img src={EmptyProfile} className="w-8 h-8 " />
                  </div>
                  <div>
                    <span className="color-sub-text">Trade Name </span>
                    <br />
                    <span>
                      {trader?.tradeName ? (
                        <span className="text-sm font-semibold">
                          {trader.tradeName}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-500">--</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="grow-[2] basis-0 px-2">
                  <span className="color-sub-text">Owner Name </span>
                  <br />
                  <span>
                    {trader?.traderName ? (
                      <span className="text-sm font-semibold">
                        {trader.traderName}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">--</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="flex m-2">
                <div className="grow-[3.8] basis-0 px-2 flex">
                  <div className="ml-8">
                    <span className="color-sub-text">Business Type </span>
                    <br />
                    <span>
                      {trader?.regType ? (
                        <span className="text-sm font-semibold">
                          {trader.regType}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-500">--</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="grow-[2] basis-0 px-2">
                  <span className="color-sub-text">Short Code </span>
                  <br />
                  <span>
                    {trader?.tradeScode ? (
                      <span className="text-sm font-semibold">
                        {trader.tradeScode}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">--</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="flex">
                <div>
                  <img
                    src={location}
                    alt=""
                    className="h-[30px] w-[30px] ml-[10px] mr-[10px] mt-3"
                  />
                </div>
                <div className="mt-2">
                  <span className="color-sub-text">Business Address</span>
                  <br />

                  <span className="font-semibold text-sm">
                    {trader?.bsnAcc[0].addrLine &&
                      trader?.bsnAcc[0].city &&
                      trader?.bsnAcc[0].state &&
                      trader?.bsnAcc[0].pincode ? (
                      <span>
                        {trader?.bsnAcc[0].addrLine}
                        <br />
                        {trader?.bsnAcc[0].city}, {trader?.bsnAcc[0].state}{" "}
                        {trader?.bsnAcc[0].pincode}
                      </span>
                    ) : (
                      "--"
                    )}
                  </span>
                </div>
              </div>
              <div className="flex mt-2">
                <div className="grow-[3.8] basis-0 px-2 flex">
                  <div className="m-1">
                    <img src={market} className="w-8 h-8 " />
                  </div>
                  <div>
                    <span className="color-sub-text">Market Name</span>
                    <br />
                    <span>
                      {trader?.marketName ? (
                        <span className="text-sm font-semibold">
                          {trader.marketName}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-500">--</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="grow-[2] basis-0 px-2">
                  <span className="color-sub-text">Shop Number </span>
                  <br />
                  <span>
                    {trader?.shopNum ? (
                      <span className="text-sm font-semibold">
                        {trader.shopNum}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">--</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="flex m-2">
                <div className="grow-[3.8] basis-0 px-2 flex">
                  <div className="ml-8">
                    <span className="color-sub-text">Email Address </span>
                    <br />
                    <span>
                      {trader?.emailId ? (
                        <span className="text-sm font-semibold">
                          {trader.emailId}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-500">--</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="grow-[2] basis-0 px-2">
                  <span className="color-sub-text">Trade Description </span>
                  <br />
                  <span>
                    {trader?.tradeDesc ? (
                      <span className="text-sm font-semibold">
                        {trader.tradeDesc}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">--</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="flex">
                <div className="grow-[3.8] basis-0 px-2 flex">
                  <div className="m-1">
                    <img src={phone} className="w-8 h-8 " />
                  </div>
                  <div>
                    <span className="color-sub-text">Mobile Number </span>
                    <br />
                    <span>
                      {trader?.mobile ? (
                        <span className="text-sm font-semibold">
                          {trader.mobile}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-500">--</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="grow-[2] basis-0 px-2">
                  <span className="color-sub-text">Alternate Number </span>
                  <br />
                  <span>
                    {trader?.altMobile ? (
                      <span className="text-sm font-semibold">
                        {trader.altMobile}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">--</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="flex mt-2">
                <div className="grow-[3.8] basis-0 px-2 flex">
                  <div className="m-1">
                    {trader?.rmProfilePic ? (
                      <img
                        src={trader?.rmProfilePic}
                        alt=""
                        className="w-8 h-8"
                      />
                    ) : (
                      <img src={EmptyProfile} alt="" className="w-8 h-8" />
                    )}
                  </div>
                  <div>
                    <span className="color-sub-text">
                      Relationship Manager{" "}
                    </span>
                    <br />
                    <span>
                      {trader?.relMgr ? (
                        <span className="text-sm font-semibold">
                          {trader.relMgr}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-500">--</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="grow-[2] basis-0 px-2">
                  <span className="color-sub-text">RM Mobile Number </span>
                  <br />
                  <span>
                    {trader?.rmMobile ? (
                      <span className="text-sm font-semibold">
                        {trader.rmMobile}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">--</span>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className=" h-auto border-gray mt-[20px] ml-[20px] mr-[20px] br-10 bg-white">
              <div className="h-[50px] border-b bg-light-primary rounded-t-md flex items-center">
                <img src={bank} alt="mandii" className="h-5 ml-[20px] " />{" "}
                <span className="ml-[10px] text-base">Bank Details</span>
              </div>
              <div className="flex mt-2">
                <div className="grow-[3.8] basis-0 px-2 flex">
                  <div className="m-1">
                    <img src={EmptyProfile} className="w-8 h-8 mr-1" />
                  </div>
                  <div>
                    <span className="color-sub-text">Account Holder </span>
                    <br />
                    <span>
                      {trader?.accountHolder ? (
                        <span className="text-sm font-semibold">
                          {trader.accountHolder}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-500">--</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="grow-[2] basis-0 px-2">
                  <span className="color-sub-text">Account Number</span>
                  <br />
                  <span>
                    {trader?.accountNum ? (
                      <span className="text-sm font-semibold">
                        {trader.accountNum}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">--</span>
                    )}
                  </span>
                </div>
              </div>

              <div className="flex m-2">
                <div className="grow-[3.8] basis-0 px-2 flex ml-1">
                  <div className="ml-8">
                    <span className="color-sub-text">Bank Name </span>
                    <br />
                    <span>
                      {trader?.bankName ? (
                        <span className="text-sm font-semibold">
                          {trader.bankName}
                        </span>
                      ) : (
                        <span className="text-sm text-gray-500">--</span>
                      )}
                    </span>
                  </div>
                </div>

                <div className="grow-[2] basis-0 px-2">
                  <span className="color-sub-text">IFSC Code </span>
                  <br />
                  <span>
                    {trader?.ifsc ? (
                      <span className="text-sm font-semibold">
                        {trader.ifsc}
                      </span>
                    ) : (
                      <span className="text-sm text-gray-500">--</span>
                    )}
                  </span>
                </div>
              </div>
            </div>

            <div className=" h-auto border-gray m-[20px] br-10 bg-white">
              <div className="h-[50px] border-b bg-light-primary rounded-t-md flex items-center">
                <img src={document} alt="document" className="h-6 ml-[20px]" />
                <span className="ml-[10px] text-base">Documents</span>
              </div>

              <div>
                <div>
                  <span className="color-sub-text mt-[30px] ml-4 mb-[10px]">
                    Aadhar Card
                  </span>
                  <div className="flex overflow-auto">
                    {trader?.kycDocs.filter((doc) => doc.type == "AADHAR")
                      .length !== 0 ? (
                      trader?.kycDocs
                        .filter((doc) => doc.type == "AADHAR")
                        .map((image, i) => (
                          <div
                            className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                            style={{
                              width: "33.333%",
                              height: "10rem",
                            }}
                          >
                            <a href={image.url} target="_blank">
                              <DocumentPreview
                                url={image.url}
                                docType={
                                  image.url.includes(".pdf") ? "pdf" : "image"
                                }
                              />
                            </a>
                          </div>
                        ))
                    ) : (
                      <div
                        className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        Not Uploaded Aadhar
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-[30px]">
                  <span className="color-sub-text ml-4">
                    Permanent Account Number(PAN)
                  </span>
                  <div className="flex overflow-auto">
                    {trader?.kycDocs.filter((doc) => doc.type == "PAN")
                      .length !== 0 ? (
                      trader?.kycDocs
                        .filter((doc) => doc.type == "PAN")
                        .map((image, i) => (
                          <div
                            className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                            style={{
                              width: "33.333%",
                              height: "10rem",
                            }}
                          >
                            <a href={image.url} target="_blank">
                              <DocumentPreview
                                url={image.url}
                                docType={
                                  image.url.includes(".pdf") ? "pdf" : "image"
                                }
                              />
                            </a>
                          </div>
                        ))
                    ) : (
                      <div
                        className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        Not Uploaded PAN
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-[30px]">
                  <span className="color-sub-text mt-[23px] ml-4 mb-[10px]">
                    Bank Statement
                  </span>
                  <div className="flex overflow-auto">
                    {trader?.kycDocs.filter(
                      (doc) => doc.type == "BANK_STATEMENT"
                    ).length !== 0 ? (
                      trader?.kycDocs
                        .filter((doc) => doc.type == "BANK_STATEMENT")
                        .map((image, i) => (
                          <div
                            className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                            style={{
                              width: "33.333%",
                              height: "10rem",
                            }}
                          >
                            <a href={image.url} target="_blank">
                              <DocumentPreview
                                url={image.url}
                                docType={
                                  image.url.includes(".pdf") ? "pdf" : "image"
                                }
                              />
                            </a>
                          </div>
                        ))
                    ) : (
                      <div
                        className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        Not Uploaded Bank_Statement
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-[30px]">
                  <span className="color-sub-text mt-[23px] mb-[10px] ml-4">
                    ITR Document
                  </span>
                  <div className="flex overflow-auto">
                    {trader?.kycDocs.filter((doc) => doc.type == "ITR")
                      .length !== 0 ? (
                      trader?.kycDocs
                        .filter((doc) => doc.type == "ITR")
                        .map((image, i) => (
                          <div
                            className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                            style={{
                              width: "33.333%",
                              height: "10rem",
                            }}
                          >
                            <a href={image.url} target="_blank">
                              <DocumentPreview
                                url={image.url}
                                docType={
                                  image.url.includes(".pdf") ? "pdf" : "image"
                                }
                              />
                            </a>
                          </div>
                        ))
                    ) : (
                      <div
                        className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        Not Uploaded ITR
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-[30px]">
                  <span className="color-sub-text mt-[23px] mb-[10px] ml-4">
                    Trade/Mandi License
                  </span>
                  <div className="flex overflow-auto">
                    {trader?.kycDocs.filter(
                      (doc) => doc.type == "TRADE_LICENSE"
                    ).length !== 0 ? (
                      trader?.kycDocs
                        .filter((doc) => doc.type == "TRADE_LICENSE")
                        .map((image, i) => (
                          <div
                            className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                            style={{
                              width: "33.333%",
                              height: "10rem",
                            }}
                          >
                            <a href={image.url} target="_blank">
                              <DocumentPreview
                                url={image.url}
                                docType={
                                  image.url.includes(".pdf") ? "pdf" : "image"
                                }
                              />
                            </a>
                          </div>
                        ))
                    ) : (
                      <div
                        className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        Not Uploaded Trade_License
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-[30px] mb-[20px]">
                  <span className="color-sub-text mt-[23px] mb-[10px] ml-4">
                    Empty Invoice/Business Card
                  </span>
                  <div className="flex overflow-auto">
                    {trader?.kycDocs.filter((doc) => doc.type == "INVOICE")
                      .length !== 0 ? (
                      trader?.kycDocs
                        .filter((doc) => doc.type == "INVOICE")
                        .map((image, i) => (
                          <div
                            className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                            style={{
                              width: "33.333%",
                              height: "10rem",
                            }}
                          >
                            <a href={image.url} target="_blank">
                              <DocumentPreview
                                url={image.url}
                                docType={
                                  image.url.includes(".pdf") ? "pdf" : "image"
                                }
                              />
                            </a>
                          </div>
                        ))
                    ) : (
                      <div
                        className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        Not Uploaded Invoice
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-[30px]">
                  <span className="color-sub-text ml-4">
                    UDC / PDC
                  </span>
                  <div className="flex overflow-auto">
                    {trader?.kycDocs.filter((doc) => doc.type == "PDC")
                      .length !== 0 ? (
                      trader?.kycDocs
                        .filter((doc) => doc.type == "PDC")
                        .map((image, i) => (
                          <div
                            className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                            style={{
                              width: "33.333%",
                              height: "10rem",
                            }}
                          >
                            <a href={image.url} target="_blank">
                              <DocumentPreview
                                url={image.url}
                                docType={
                                  image.url.includes(".pdf") ? "pdf" : "image"
                                }
                              />
                            </a>
                          </div>
                        ))
                    ) : (
                      <div
                        className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        Not Uploaded UDC / PDC
                      </div>
                    )}
                  </div>
                </div>
                <div className="mt-[30px] mb-2">
                  <span className="color-sub-text ml-4">
                    Other Documents
                  </span>
                  <div className="flex overflow-auto">
                    {trader?.kycDocs.filter((doc) => doc.type == "OTHER")
                      .length !== 0 ? (
                      trader?.kycDocs
                        .filter((doc) => doc.type == "OTHER")
                        .map((image, i) => (
                          <div
                            className="ml-3 shrink-0 overflow-hidden border-2 p-2 rounded flex m-2"
                            style={{
                              width: "33.333%",
                              height: "10rem",
                            }}
                          >
                            <a href={image.url} target="_blank">
                              <DocumentPreview
                                url={image.url}
                                docType={
                                  image.url.includes(".pdf") ? "pdf" : "image"
                                }
                              />
                            </a>
                          </div>
                        ))
                    ) : (
                      <div
                        className="ml-4 mt-1 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center"
                        style={{
                          width: "33.333%",
                          height: "10rem",
                        }}
                      >
                        Not Uploaded Other Documents
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {activeTab !== "Ledger" ? (
          <div className="w-[60%] mr-[10px]">
            <div>
              <div className="h-[80px] border-gray mt-[20px] mb-[20px] br-10 bg-white">
                <div className="m-4 flex justify-between">
                  <div className="">
                    {trader?.tradeType === "SOURCE" && (
                      <div>
                        <span className="color-sub-text">Total Receivable</span>
                        <p className="font-semibold">{calculateTotal()}</p>
                      </div>
                    )}
                    {trader?.tradeType !== "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Total Payable</p>
                        <p className="font-semibold">{calculateTotal()}</p>
                      </div>
                    )}
                  </div>
                  <div className="border ml-2 mt-1 w-[0px] h-[50px] border-gray-200"></div>

                  <div className="">
                    <p className="color-sub-text">Platform Fees</p>
                    {trader?.tradeType === "SOURCE" && (
                      <p className="font-semibold">{calculatePlatform()}</p>
                    )}
                    {trader?.tradeType !== "SOURCE" && (
                      <p className="font-semibold">{calculatePlatform()}</p>
                    )}
                  </div>
                  <div className="border ml-2 mt-1 w-[0px] h-[50px] border-gray-200"></div>

                  <div className="">
                    {trader?.tradeType === "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Received Till Date</p>
                        <p className="font-semibold">{calculateTillDate()}</p>
                      </div>
                    )}
                    {trader?.tradeType !== "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Paid Till Date</p>

                        <p className="font-semibold">{calculateTillDate()}</p>
                      </div>
                    )}
                  </div>
                  <div className="border ml-2 mt-1 w-[0px] h-[50px] border-gray-200"></div>

                  <div className="">
                    {trader?.tradeType === "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Balance Receivable</p>
                        <p className="font-semibold">{calculateBalance()}</p>
                      </div>
                    )}
                    {trader?.tradeType !== "SOURCE" && (
                      <div>
                        <p className="color-sub-text">Balance Payable</p>

                        <p className="font-semibold">{calculateBalance()}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {loading ? (
              <span className="flex justify-center items-center h-screen">
                <CircularProgress />
              </span>
            ) : (
              <div className="overflow-y-scroll"
              style={{ height: "calc(100vh - 15rem)"}}
              
              >
                <div className="bg-light-primary px-6 py-2 mt-2 rounded-t-md flex">
                  <span className="text-base grow-[2] basis-0">Trade</span>
                  <span className="text-base grow-[3] basis-0">From</span>
                  <span className="text-base flex-grow">Status</span>
                </div>

                <div className="rounded-t-md">
                  {trader?.trades !== null ? (
                    trader?.trades.map((trade) => (
                      // <div
                      // onClick={() => {
                      //   navigate(`/allDetails/${trader.traderId}/${trade.tradeId}`);
                      //   dispatch(
                      //     updateTraderId({
                      //       traderId: trader.traderId,
                      //       tradeStatus: "ALL",
                      //     })
                      //   );
                      // }}
                      // >
                      <div className="border-gray mb-4 bg-white">
                        <div className="flex p-2 justify-between items-center">
                          <div className="px-2">
                            <span className="color-blue">
                              Global ID: {trade.tradeId}
                            </span>
                            <br />
                            <span className="font-semibold">
                              Trade ID: {trade.traderSeq}
                            </span>
                          </div>

                          <div className="flex">
                            <span className="pt-2">
                              <img
                                src={EmptyProfile}
                                alt="EmptyProfile"
                                className="h-8 mr-2"
                              />
                            </span>

                            <span className="">
                              <span className="font-semibold mr-2 text-sm">
                                {trade.tradeName}
                              </span>
                              <br></br>
                              <span className="text-sm">
                                Trader - {trade.traderId}|{" "}
                                {getMaskedMobileNumber(trade.mobile || "")}
                              </span>
                              <br></br>
                              <span className="color-sub-text text-sm">
                                {trade.tradeName}
                              </span>
                            </span>
                          </div>

                          <div className="flex px-2">
                            <div className="pt-1">
                              <img
                                src={vehicle}
                                alt="vehicle"
                                className="h-8 w-8"
                              />
                            </div>
                            <div className="ml-[8px] text-sm">
                              <span className="font-semibold ">
                                {trader.tradeType == "SOURCE"
                                  ? trade.srcTradeStatus
                                  : trade.destTradeStatus}
                              </span>
                              <br></br>
                              <span>{trade.timeStamp}</span>
                            </div>
                          </div>
                        </div>

                        <div className="border-b"></div>
                        <div className="flex text-sm justify-between items-center">
                          <div className="px-4">
                            <span className="color-sub-text">Trade Type</span>
                            <br></br>
                            <span className="font-semibold">
                              {trade.tradeMode}
                            </span>
                          </div>
                          <div className="border mt-1 w-[0px] h-[50px] border-gray-200"></div>

                          <div className="flex justify-center items-center">
                            {trader?.tradeType === "SOURCE" && (
                              <div>
                                <span className="color-sub-text">
                                  Total Receivable
                                </span>
                                <br />
                                <p className="font-semibold">
                                  {getCurrencyNumberWithSymbol(trade.totalReceivable) || "0"}
                                </p>
                              </div>
                            )}
                            {trader?.tradeType !== "SOURCE" && (
                              <div>
                                <span className="color-sub-text">
                                  Total Payable
                                </span>{" "}
                                <br />
                                <p className="font-semibold">
                                {getCurrencyNumberWithSymbol(trade.totalPayable) || "0"}
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="border mt-1 w-[0px] h-[50px]  border-gray-200"></div>

                          <div className="flex justify-center items-center">
                            {trader?.tradeType === "SOURCE" && (
                              <div>
                                <p className="color-sub-text">
                                  Received Till Date
                                </p>
                                <p className="font-semibold">
                                {getCurrencyNumberWithSymbol(trade.outwardPmt) || "0"}
                                </p>
                              </div>
                            )}
                            {trader?.tradeType !== "SOURCE" && (
                              <div>
                                <p className="color-sub-text">Paid Till Date</p>
                                <p className="font-semibold">
                                {getCurrencyNumberWithSymbol(trade.inwardPmt) || "0"}
                                </p>
                              </div>
                            )}
                          </div>
                          <div className="border mt-1 w-[0px] h-[50px]  border-gray-200"></div>

                          <div className="flex justify-center items-center px-2">
                            {trader?.tradeType === "SOURCE" && (
                              <div>
                                <p className="color-sub-text">
                                  Balance Receivable
                                </p>
                                <p className="font-semibold">
                                {getCurrencyNumberWithSymbol(trade.totalReceivable - trade.outwardPmt) || "0"}
                                </p>
                              </div>
                            )}
                            {trader?.tradeType !== "SOURCE" && (
                              <div>
                                <p className="color-sub-text">
                                  Balance Payable
                                </p>
                                <p className="font-semibold">
                                  {getCurrencyNumberWithSymbol(trade.totalPayable - trade.inwardPmt) || "0"}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="border rounded-sm bg-white flex items-center justify-center p-4 text-sm text-center rounded-b-md"
                      style={{
                        width: "100%",
                        height: "10rem",
                      }}
                    >
                      No Trades Available....
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div
            className=" w-[55%] mt-4 ml-2 mr-2 border rounded-sm bg-gray-50 flex items-center justify-center p-4 text-sm text-center br-10"
            style={{
              height: "10rem",
            }}
          >
            No Data Available....
          </div>
        )}
      </div>
    </div>
  );
};

export default TradeDetails;

import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

import "../../fonts"; // Ensure the fonts are registered
import watermark from "../../assets/images/watermark.png"; // Make sure this path is correct
import ono_logo from "../../assets/images/ono_logo_pdf.png";
import logo_op from "../../assets/images/logo_op.png";
import store from "../../store";
import {
  getCurrencyNumberWithOutSymbol,
  getCurrencyNumberWithSymbol,
  numberToWordsWithDecimals,
} from "../../helpers/get-currency-number";
import {
  getCropUnit,
  getCropUnitFull,
  getWeightUnits,
} from "../../helpers/getText";
import moment from "moment";
const invoiceData = {
  // header: "Dynamic Invoice Header",
  // footer: "Dynamic Invoice Footer",
  // invoiceDate: "2024-08-23",
  // invoiceNumber: "12345",
  items: [
    {
      name: "Onion",
      quantity: " 876 Bags",
      unitPrice: "2400.00 KGS",
      total: "15,588.00",
    },
  ],
};
const styles = StyleSheet.create({
  page: {
    paddingTop: 80, // Adjust to leave space for the header
    paddingBottom: 60, // Adjust to leave space for the footer
    paddingHorizontal: 40,
    fontFamily: "Roboto",
  },
  header: {
    fontSize: 11,
    color: "#000000",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "10px solid #16A02C",
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    // marginBottom: 70,
    paddingTop: 20,
    borderBottom: "1px solid black",
    // width: "100%",
  },
  logo: {
    width: 80,
  },
  header_head_main: {
    fontSize: 14,
    textAlign: "center",
    marginLeft: "20%",
    marginRight: "auto",
  },
  header_head: {
    fontSize: 14,
    textAlign: "center",
  },
  footer: {
    fontSize: 12,
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "right",

    // paddingLeft: 40,
    paddingRight: 40,
    // borderTop: "1px solid black",
    paddingTop: 20,
    borderBottom: "5px solid #16A02C",
    // width: "100%",
    // backgroundColor: "red",
    // color: "grey",
  },
  // footer: {
  //   fontSize: 12,
  //   position: "absolute",
  //   bottom: 10,
  //   left: 0,
  //   right: 0,
  //   textAlign: "left",
  //   paddingLeft: 40,
  //   paddingRight: 40,
  //   borderTop: "1px solid black",
  //   paddingTop: 20,
  //   // color: "grey",
  // },
  watermark: {
    position: "absolute",
    top: "30%",
    left: "15%",
    width: 300,
    height: 300,
    // opacity: 0.3, // Make the watermark semi-transparent
    // transform: "rotate(-45deg)",
    zIndex: -1, // Ensure it's behind the content
  },
  section: {
    marginBottom: 20,
    fontSize: 11,
    paddingTop: 20,
    lineHeight: 1.5,
  },
  table: {
    display: "table",
    width: "100%",
    marginTop: 30,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000",
    borderCollapse: "collapse",
    borderBottom: "0px", // This should help to collapse the borders
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1, // Apply border only to the bottom
    borderColor: "#000",
  },
  tableCol: {
    width: "20%", // Ensuring the width adds up to 100%
    padding: 5,
    borderLeftWidth: 1, // Apply border only to the left (except for first column)
    borderColor: "#000",
    // borderBottomWidth: 1, // Apply border to bottom to create a complete row
  },
  firstCol: {
    width: "10%",
    padding: 5,
    // borderBottomWidth: 1,
    borderColor: "#000",
  },
  tablBigCol: {
    width: "40%",
  },
  tablSmCol: {
    width: "10%",
  },
  para: {
    lineHeight: 1.5,
  },
  subHeading: {
    fontSize: 12,
    fontWeight: "bold",
    marginVertical: 5,
  },
  paragraph: {
    marginBottom: 10,
  },
  list: {
    marginLeft: 10,
    marginBottom: 10,
    listStyle: "disc",
  },
  listItem: {
    marginBottom: 5,
    // marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
  },
  listItemLeft: {
    justifyContent: "flex-end",
    textAlign: "right",
  },
  footerPara: {
    fontSize: 11,
    color: "#121212",
  },
  footerParaSm: {
    fontSize: 10,
    color: "#000000",
  },
  bold: {
    fontWeight: "bold",
  },
  space: {
    paddingBottom: 10,
  },
  pageno: {
    paddingTop: 10,
    textAlign: "center",
  },
  left: {
    textAlign: "left",
  },
  right: {
    textAlign: "right",
  },
  hindiDiv: {
    // borderTop: "1px solid black",
    // padding: 20,
    paddingBottom: 10,
    paddingTop: 10,
    fontFamily: "NotoSansTamil",
  },
  mainpara: {
    paddingBottom: 10,
  },
  visible: {
    visibility: "hidden",
  },
  relative: {
    position: "relative",
  },
  logo_op: {
    position: "absolute",
    top: "18%",
    left: "0%",
    width: 100,
    height: 50,
  },
  platform: {
    // borderTop: "1px solid black",
    borderBottom: "1px solid black",
    // padding: 5,
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    gap: 20,
    // justifyContent: "center",
    width: "100%",
  },
  col1: {
    width: "50%",
  },
  col2: {
    width: "50%",
  },
  justifyContent: {
    justifyContent: "space-between",
  },
  border: {
    borderTop: "1px dotted black",
    // paddingBottom: 20,
    paddingTop: 20,
  },
  padding: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  borderLeft: {
    borderLeft: "0px",
  },
  borderTop: {
    borderTop: "0px",
  },
  mergedCol: {
    width: "60%", // This merges two columns into one by doubling the width
    padding: 5,
    borderLeftWidth: 1,
    borderColor: "#000",
    // borderLeft: "0px",
    borderBottomWidth: "0px",
  },
  mergedColLeft: {
    width: "50%",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  leftCol: {
    width: "50%", // Width for the "label" part
  },
  rightCol: {
    width: "50%", // Width for the "value" part
    // textAlign: "right", // Align the value to the right
  },
  borderTopPx: {
    borderTop: "1px solid black",
  },
  padding0: {
    padding: 0,
  },
  padding5: {
    padding: 5,
  },
  bullet: {
    fontSize: 15,
    marginRight: 5,
  },
  th: {
    fontSize: 9,
  },
  textRight: {
    textAlign: "right",
    // backgroundColor: "red",
  },
  tableCellLeftTotal: {
    width: "60%",
  },
  tableCellRightTotal: {
    width: "40%",
  },
  signature: {
    width: 100,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginLeft: "auto",
    alignItems: "flex-end",
  },
  gap0: {
    gap: 0,
  },
});

// Watermark component to be used on each page
const Watermark = () => (
  <View fixed style={styles.watermark}>
    <Image src={watermark} style={styles.watermark} />
  </View>
);

const TapalInvoiceDocs = ({ details, type, status }) => (
  <>
    {/* <Page size="A4" style={styles.page}> */}
    <Watermark />
    {/* Header */}
    <View fixed style={styles.header}>
      <Image src={ono_logo} style={styles.logo} />
      <Text style={styles.header_head}>PROVISIONAL TAPAL</Text>
      <View>
        <Text>Invoice No : {details?.srcInvId || "-"}</Text>
        <Text>Date : {moment(details?.date).format("DD-MMM-YYYY")}</Text>
        <Text>Trade ID : {details?.traderSeq}</Text>
      </View>
    </View>
    <View style={styles.platform}>
      <Text>Platform Facilitator: ONO ARK INDIA PVT. LTD.</Text>
    </View>
    {/* Table */}
    <View style={styles.section}>
      <View style={styles.flex}>
        <View style={styles.col1}>
          <Text>Consignor Address:</Text>
          <View>
            <Text style={styles.bold}>{details?.srcTradeName}</Text>
            <Text>{details?.srcAddrLine || "-"}</Text>
          </View>
        </View>
        <View style={styles.col2}>
          <View>
            <Text>Consignee Address:</Text>
            <View>
              <Text style={styles.bold}>{details?.destTradeName}</Text>
              <Text>{details?.destAddrLine || "-"}</Text>
            </View>
          </View>
          <View style={styles.padding}>
            <Text>Shipping Address:</Text>
            <View>
              <Text style={styles.bold}>{details?.destTradeName}</Text>
              <Text>{details?.destAddrLine || "-"}</Text>
            </View>
          </View>
        </View>
      </View>

      <View style={styles.border}></View>
      <View style={styles.flex}>
        <View style={styles.col1}>
          <View>
            <View style={styles.flex}>
              <Text style={styles.bold}>Dispatch Date:</Text>
              <Text>{moment(details?.date).format("DD-MMM-YYYY")}</Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.bold}>Truck Number:</Text>
              <Text>{details?.srcVehNum}</Text>
            </View>
          </View>
        </View>
        <View style={styles.col2}>
          <View>
            <View style={styles.flex}>
              <Text style={styles.bold}>Driver Name:</Text>
              <Text>-</Text>
            </View>
            <View style={styles.flex}>
              <Text style={styles.bold}>Driver No:</Text>
              <Text>-</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <View style={styles.firstCol}>
            <Text style={[styles.tableCell, styles.th]}>#</Text>
          </View>
          <View style={[styles.tableCol, styles.tablBigCol]}>
            <Text style={[styles.tableCell, styles.th]}>Particular</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.th]}>Quantity</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.th]}>Weight(KGS)</Text>
          </View>
          <View style={styles.tableCol}>
            <Text style={[styles.tableCell, styles.th]}>Freight Amount(₹)</Text>
          </View>
        </View>
        {details.lineItems.map((item, index) => (
          <View style={styles.tableRow} key={index}>
            <View style={styles.firstCol}>
              <Text style={styles.tableCell}>{index + 1}</Text>
            </View>
            <View style={[styles.tableCol, styles.tablBigCol]}>
              <Text style={styles.tableCell}>{item.cropName}</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {getCropUnitFull(item.qtyUnit, item.qty)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {getCurrencyNumberWithOutSymbol(item.bayerWeight)}
              </Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {getCurrencyNumberWithOutSymbol(details.freightAmt)}
              </Text>
            </View>
          </View>
        ))}
        <View style={styles.tableRow}>
          <View
            style={[styles.mergedCol, styles.borderLeft, styles.mergedColLeft]}
          ></View>
          <View style={[styles.mergedCol, styles.borderLeft, styles.padding0]}>
            {/* First sub-row */}
            <View style={[styles.flex, styles.padding5]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.textRight,
                  styles.bold,
                  styles.tableCellLeftTotal,
                ]}
              >
                Freight Amount:
              </Text>

              <Text
                style={[
                  styles.tableCell,
                  styles.bold,
                  styles.tableCellRightTotal,
                ]}
              >
                {getCurrencyNumberWithSymbol(details?.freightAmt) || 0}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.tableRow}>
          <View
            style={[styles.mergedCol, styles.borderLeft, styles.mergedColLeft]}
          >
            <Text style={[styles.tableCell, styles.bold]}>
              Balance Freight in words:
            </Text>
            <Text style={styles.tableCell}>
              {numberToWordsWithDecimals(
                Number(details?.freightAmt) - Number(details?.freightAdv)
              )}
              {/* Eighty Two Thousand Five Hundred Eighty Eight Rupees Only */}
            </Text>
          </View>
          <View style={[styles.mergedCol, styles.padding0]}>
            {/* First sub-row */}
            <View style={[styles.flex, styles.padding5]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.textRight,
                  styles.tableCellLeftTotal,
                ]}
              >
                Advance Freight Paid:
              </Text>
              <Text style={[styles.tableCell, styles.tableCellRightTotal]}>
                {getCurrencyNumberWithSymbol(details?.freightAdv) || 0}
              </Text>
            </View>

            {/* Second sub-row */}
            <View style={[styles.flex, styles.borderTopPx, styles.padding5]}>
              <Text
                style={[
                  styles.tableCell,
                  styles.textRight,
                  styles.bold,
                  styles.tableCellLeftTotal,
                ]}
              >
                Balance Freight:
              </Text>
              <Text
                style={[
                  styles.tableCell,
                  styles.bold,
                  styles.tableCellRightTotal,
                ]}
              >
                {getCurrencyNumberWithSymbol(
                  Number(details?.freightAmt) - Number(details?.freightAdv)
                ) || 0}
              </Text>
            </View>
          </View>

          {/* <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Advance Freight Paid:</Text>
            </View>
            <View style={[styles.tableCol, styles.borderLeft, styles.bold]}>
              <Text style={styles.tableCell}>20,000.00</Text>
            </View> */}
        </View>
        {type == "DEST" ? (
          <View style={styles.tableRow}>
            <View
              style={[
                styles.mergedCol,
                styles.borderLeft,
                styles.mergedColLeft,
              ]}
            >
              <Text style={[styles.tableCell, styles.bold]}>Payable To:</Text>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>Bank Name: </Text>
                <Text style={styles.bold}>ICICI Bank</Text>
              </View>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>Beneficiary Name: </Text>
                <Text style={styles.bold}>ONO ARK INDIA PVT. LTD.</Text>
              </View>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>Account No: </Text>
                <Text style={styles.bold}>777705144845</Text>
              </View>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>IFSC Code: </Text>
                <Text style={styles.bold}>ICIC0000357</Text>
              </View>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>Branch: </Text>
                <Text style={styles.bold}>HSR Layout</Text>
              </View>
            </View>
            <View style={styles.mergedCol}></View>
          </View>
        ) : (
          <View style={styles.tableRow}>
            <View
              style={[
                styles.mergedCol,
                styles.borderLeft,
                styles.mergedColLeft,
              ]}
            >
              <Text style={[styles.tableCell, styles.bold]}>Paid To:</Text>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>Bank Name: </Text>
                <Text style={styles.bold}>{details?.srcBankName}</Text>
              </View>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>Beneficiary Name: </Text>
                <Text style={styles.bold}>
                  {details?.srcAccountHolder || "-"}
                </Text>
              </View>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>Account No: </Text>
                <Text style={styles.bold}>{details?.srcAccount}</Text>
              </View>
              <View style={[styles.tableCell, styles.flex, styles.gap0]}>
                <Text>IFSC Code: </Text>
                <Text style={styles.bold}>{details?.srcIfsc}</Text>
              </View>
              <Text style={styles.tableCell}>
                <Text>Branch: </Text>
                <Text style={styles.bold}>-</Text>
              </Text>
            </View>
            <View style={styles.mergedCol}></View>
          </View>
        )}
      </View>
    </View>
    {/* Footer with Page Numbers */}

    <View style={styles.footer}>
      <View>
        {/* <Image src={sig} style={styles.signature} /> */}
        <Text>SIGNATURE OF {type == "DEST" ? "CONSIGNEE" : "CONSIGNER"}</Text>
      </View>
      {/* <Text style={styles.subHeading}>Terms and Conditions</Text>
        <View style={styles.list}>
          <Text style={styles.listItem}>
            <Text style={styles.bullet}>•</Text>
            Any commodity price fluctuations / reduced risks that may arise. Any
            and all risks associated with post arrivals delays or liquidation
            challenges.
          </Text>
          <Text style={styles.listItem}>
            <Text style={styles.bullet}>•</Text>
            While ONO facilitates the dispute resolution process, ONO is not
            responsible for taking any rejections of trades due to quality
            issues that may occur during the course of the transaction or trade.
          </Text>
          <Text style={styles.listItem}>
            <Text style={styles.bullet}>•</Text>
            Any unlawful images or data that may inadvertently appear on the
            platform despite its efforts to ensure that all data and images
            published on the platform comply with applicable laws.
          </Text>
          <Text style={styles.listItem}>
            <Text style={styles.bullet}>•</Text>
            ONO assumes no responsibility for any information or images
            requested by a partner for publication on the platform that do not
            conform to legal requirements
          </Text>
        </View> */}
    </View>
    {/* </Page> */}
  </>
);

export default TapalInvoiceDocs;
